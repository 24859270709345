import React, { useState } from "react";
import Header from "../Componets/Header/Header";
import StartupIndiaFooter from "../../src/Componets/startupIndiaFooter";
import './ContactsCss.css'

const KeyContacts = (props) => {


    return (
        <>
            <div style={{ backgroundColor: "#fff", width: "100%", color: "black", }}>



                <Header />
                <div className='main page-content'>
                    <div className='container'>
                        <div className='title'>Startup Support Team</div>
                        <div className="accordion mt-3" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading1">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                        Key Contact
                                    </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Shri Pankaj Dixit, IAS</h2>
                                                            <h4>Director Industries <br />Department of Industries, GoB</h4>
                                                        </div>
                                                        <p className="text">
                                                            <i className="fa fa-envelope" />&nbsp;&nbsp;dir.ind-bih@nic.in<br />
                                                            <i className="fa fa-phone" />&nbsp;&nbsp;0612-2235812
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        Nodal Officer
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Shri Nagendra Sharma</h2>
                                                            <h4>Deputy Director
                                                                Technical Development
                                                                Department of Industries</h4>
                                                        </div>
                                                        <p className="text">
                                                            <i className="fa fa-phone" />&nbsp;&nbsp;7320923219
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Rakesh Kumar</h2>
                                                            <h4>Industry Extension Officer</h4>
                                                        </div>
                                                        <p className="text">
                                                            <i className="fa fa-phone" />&nbsp;&nbsp;7320923212
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Rajan Kishore</h2>
                                                            <h4>Industry Extension Office</h4>
                                                        </div>
                                                        <p className="text">
                                                            <i className="fa fa-phone" />&nbsp;&nbsp;7320923209
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Sudarshan Chakravarty</h2>
                                                            <h4>Consultant (Startup)</h4>
                                                        </div>
                                                        <p className="text">
                                                            <i className="fa fa-phone" />&nbsp;&nbsp;6287797918
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Startup Wing
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                         
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Manish Kumar Ranjan</h2>
                                                            <h4>CoOrdinator Startup</h4>
                                                        </div>
                                                        <p className="text">

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Incubation Wing
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Lovely Singh</h2>
                                                            <h4>CoOrdinator Incubation</h4>
                                                        </div>
                                                        <p className="text">
                                                            {/* <i className="fa fa-phone" />&nbsp;&nbsp;9523593639 */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Finance Wing
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Megha Bhadani</h2>
                                                            <h4>Consultant (Finance)</h4>
                                                        </div>
                                                        <p className="text">
                                                            <i className="fa fa-phone" />&nbsp;&nbsp;6287797917
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Shivendra Kumar</h2>
                                                            <h4>Jr. Consultant (Finance)</h4>
                                                        </div>
                                                        <p className="text">
                                                            <i className="fa fa-phone" />&nbsp;&nbsp;6287797916
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Pushkar Parag</h2>
                                                            <h4>CoOrdinator Finance</h4>
                                                        </div>
                                                        <p className="text">
                                                            {/* <i className="fa fa-phone" />&nbsp;&nbsp;6287797917 */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Kunal Prakash</h2>
                                                            <h4>CoOrdinator Finance</h4>
                                                        </div>
                                                        <p className="text">
                                                            {/* <i className="fa fa-phone" />&nbsp;&nbsp;6287797917 */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        IT Wing
                                    </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className='row'>
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Mukesh Kumar</h2>
                                                            <h4>Jr. Consultant (IT)</h4>
                                                        </div>
                                                        <p className="text">
                                                            <i className="fa fa-phone" />&nbsp;&nbsp;8709032306
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="profile_card">
                                                    {/* <div className="img">
                                                        <img src="https://images.unsplash.com/photo-1610216705422-caa3fcb6d158?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDB8fGZhY2V8ZW58MHwyfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" />
                                                    </div> */}
                                                    <div className="infos">
                                                        <div className="name">
                                                            <h2>Preyashi Singh</h2>
                                                            <h4>CoOrdinator IT</h4>
                                                        </div>
                                                        <p className="text">
                                                            {/* <i className="fa fa-phone" />&nbsp;&nbsp;8709032306 */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <br />
                        <br />
                        <marquee style={{ color: 'red' }}>
                            <b> If you need any assistance/query, please contact us on 18003456214 or email to startup-bihar@gov.in</b><br /><br />
                        </marquee>


                    </div>




                </div>
                <StartupIndiaFooter />
            </div>
        </>
    );
};

export default KeyContacts;
