import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import './style.css';

import { useLocation } from 'react-router-dom';
import IconCard from "../../Componets/StartupIndia/iconCard/index";
import newGif from '../../assets/img/new-gif.gif';
import startupbanner1 from '../../assets/img/banner10.webp'
import startupbanner2 from '../../assets/img/banner2.webp'
import startupbanner3 from '../../assets/img/banner3.webp'
import startupbanner4 from '../../assets/img/banner5.webp'
import startupbanner6 from '../../assets/img/banner6.webp'
import startupbanner7 from '../../assets/img/banner7.webp'
import startupbanner8 from '../../assets/img/banner8.webp'
import startupbanner9 from '../../assets/img/banner9.webp'

import notice1 from '../../assets/img/notice1.png'
import important from '../../assets/pdf/Information.pdf'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getEvent, getCertificate, getDGReview, getGallery, getNotification, getTraining, getMedia } from "../../Componets/basicAction/index";
import { eventFilter, certificateFilter, dGReviewFilter, galleryFilter, notificationFilter, trainingFilter, mediaFilter } from "../../Componets/basicAction/index";
import { useFetchData } from "../../Componets/basicAction/fetchData";
import { actionNotifier } from "../../Componets/notification";
import CardCarousel from "./cardCarousel/cardCarousel";
import { useCallback } from "react";
import { useMemo } from "react";
import { urlify } from "../../shared/functions";
import BannerCarousel from "./bannerCarousel/bannerCarousel";
import VideoPlayer from "../../Componets/VideoPlayer";
import TwitterTimelineEmbed from "./TwitterTimelineEmbed";
import FacebookPostEmbed from "./TwitterTimelineEmbed";
export function StartupIndia() {
  const { views, ...otherProps } = useMemo(() => ({
    views: {
      month: true
    }
  }), [])
  const now = new Date()
  // const [eventData, setEventData] = useState([]);  
  // const { loading, error, result: galleryResult } = useFetchData(getGallery, galleryFilter);
  const [galleryData, setGalleryData] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const [dGReviewData, setDGReviewData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const onGetGallery = async () => {
    let data = await getGallery(galleryFilter);
    if (data.status) {
      setGalleryData(data);
    }
  };
  const onGetEvent = async () => {
    let data = await getEvent(eventFilter);
    if (data.status) {
      setEventData(data.data.data);
    }
  };
  const onGetNotification = async () => {
    let data = await getNotification(notificationFilter);
    if (data.status) {
      setNoticeData(data.data.data);
    }
  };
  const onGetDGReview = async () => {
    let data = await getDGReview(dGReviewFilter);
    if (data.status) {
      setDGReviewData(data.data.data);
    }
  };
  const onGetMedia = async () => {
    let data = await getMedia(mediaFilter);
    if (data.status) {
      setMediaData(data.data.data);
    }
  };

  useEffect(() => {
    onGetGallery();
    onGetEvent();
    onGetNotification();
    onGetDGReview();
    onGetMedia();
  }, []);

  const localizer = momentLocalizer(moment)
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const [eventModal, setEventModal] = useState({ "status": false, "data": {} });

  // console.log(deviceData)

  let events = [
    {
      "id": 1,
      "title": "Training programme of LDC District (In-Service)",
      "start": "2023-05-25T00:00:00.000Z",
      "end": "2023-06-21T00:00:00.000Z"
    },
    {
      "id": 2,
      "title": "Training programme of Bihar Financial Services",
      "start": "2023-06-05T00:00:00.000Z",
      "end": "2023-09-03T00:00:00.000Z"
    }
  ];
  console.log(eventData);
  const formattedEventData = eventData.map((event) => ({
    id: event.id,
    title: event.title,
    start: new Date(event.fromDate),
    end: new Date(event.toDate),
    description: <><b>{event.type}</b><br />{event.description}<br />Venue: {event.venue}</>
  }));

  console.log(formattedEventData);
  let bannerData = [{ "id": "1", "img": startupbanner1 }, { "id": "2", "img": startupbanner2 }, { "id": "3", "img": startupbanner3 }, { "id": "4", "img": startupbanner4 }, { "id": "5", "img": startupbanner6 }, { "id": "6", "img": startupbanner7 }, { "id": "8", "img": startupbanner8 }, { "id": "9", "img": startupbanner9 }];
  const [myEvents, setEvents] = useState(events)
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )

  const handleSelectEvent = useCallback(
    (event) => {
      setEventModal({ "status": true, "data": event })
    },
    [events]
  )
  return (
    <>



      <div className="row carousel-container">
        <div className="col-sm-12">
          <div className="row ps-5 pe-5">
            <div className="col-sm-10">
              <BannerCarousel carouselData={bannerData} />
            </div>
            <div className="col-sm-2">
              <div className="vertically-centered mt-3">
                <button className="w-100" style={{ background: "transparent", border: "none" }}>
                  <IconCard text={<>Student<br />Login</>} icons="fa fa-user" links={"https://bipard.co.in/"} /><br /><br />
                </button>
                <button className="w-100" style={{ background: "transparent", border: "none" }}>
                  <IconCard text={<>CCT Exam<br />Portal</>} icons="fa fa-globe" links={"http://bipardcctexam.bihar.gov.in/"} />
                </button>
                <br /><br />
                <button className="w-100" style={{ background: "transparent", border: "none" }}>
                  <IconCard text={<>Departmental<br />Exam</>} icons="fa fa-bank" links={"https://borexam.bihar.gov.in/"} />
                </button><br /><br />
                <button className="w-100" style={{ background: "transparent", border: "none" }}>
                  <IconCard text={<>RWD Exam<br />Registration portal</>} icons="fa fa-pencil-square-o" links={"http://bipard-exam-form.thecodebucket.com/"} />
                </button>
                <i id="notification-section"></i>
              </div>
            </div>

          </div>
        </div>

      </div>

      {/* <hr className="hr" /> */}
      <div className="social-media-div mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-8 notification-section" >
              <h6 className="heading text__left"><span></span> Gallery</h6>
              <hr />
              <CardCarousel galleryResult={galleryData} />
            </div>
            <div className="col-sm-4 notification-section">
              <h6 className="heading text__left"><span></span>Notifications</h6>
              <hr />
              <div className="notice-box">
                <div id="notification" className="bg-white marquee-parent">
                  <p className="marquee">
                    <ul className="notification-event" loop="1" style={{ wordWrap: "break-word" }}>

                      {
                        noticeData?.map((e, i) => (
                          <li>
                            <a href={e.fileLink ? e.fileLink : e.link} target="_blank" >
                              <img src={newGif} style={{ height: "20px" }} alt="New" />
                              {e.subject}
                            </a>
                          </li>
                        ))
                      }

                    </ul>
                  </p>
                  <i id="upcoming-events"></i>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      {/* <hr className="hr" /> */}
      <div className="social-media-div p-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-4 notification-section text-center">
              <h6 className="heading text__left"><span></span> Social Media</h6><hr />
              <FacebookPostEmbed />
            </div>
            <div className="col-sm-6 notification-section" >
              <h6 className="heading text__left"><span></span> Event Calendar</h6><hr />
              <div className="notice-box" style={{ position: "relative" }}>
                {
                  eventModal.status ?
                    <div className="event-body" onClick={(e) => { setEventModal(false) }}>
                      <div className="event-child" style={{ overflowY: "scroll" }}>
                        <div className="close-icon"><i onClick={(e) => { setEventModal(false) }} className="fa fa-close"></i></div>
                        <h5 className="mt-2 mb-0 ps-2 pe-2" style={{ wordBreak: "break-all" }}>{eventModal?.data?.title}</h5>
                        <hr />
                        <p className="mt-3">Event Date<br /><br /><b>{moment(eventModal?.data?.start).format("dddd, MMMM DD, yyyy")}<br />{eventModal?.data?.end != eventModal?.data?.start ? <>-<br />{moment(eventModal?.data?.end).format("dddd, MMMM DD, yyyy")}</> : ""}</b></p>
                        <p>{eventModal?.data?.description}</p>
                      </div>
                    </div> : ""
                }
                <Calendar
                  style={{ fontSize: "12px", height: 450, borderRadius: "10px" }}
                  localizer={localizer}
                  events={formattedEventData}
                  // event={events}
                  startAccessor="start"
                  endAccessor="end"
                  onSelectEvent={handleSelectEvent}
                  onSelectSlot={handleSelectSlot}
                  views={views}
                  defaultDate={formattedEventData && formattedEventData.length > 0 ? new Date(formattedEventData[0].start) : new Date()}
                  // defaultDate={events?.[0]?.start}
                  popup
                />
              </div>

              {/*
             <EventCard events={events} /> 
              <div id="notification" className="bg-white marquee-parent event">
                  <p className="marquee">
                    <ul className="notification-event">
                      <li> <a target="_blank" href={nationalStartupReport}>National Startup Day- 2023</a></li>
                      <li> <a target="_blank" href={Webnar}>Webinar on ‘Startup Opportunities for Student</a></li>
                      <li> <a target="_blank" href={PatnaScience}>Patna Science College outreach program for students</a></li>
                      <li> <a target="_blank" href={Chandragupta}>Chandragupta Institute of Management Patna outreach program for students</a></li>
                      <li> <a target="_blank" href={outreach}>A N College outreach program for students</a></li>
                      <li> <a href="#">Launch of the Bihar Start Up Policy 2022 and Portal Launch on July 29th, 2022</a></li>
                    </ul>
                  </p>
                </div> */}

            </div>
            {/* <div className="row justify-content-center pt-5">
              <div className="col-sm-11 notification-section">
                <h6 className="heading text__left"><span></span> Media Section</h6><hr />
                <div className="notice-box">
                  <div className="border bg-white" style={{ borderRadius: "10px", height: "220px", overflowX: "hidden", overflowY: "hidden", background: 'lightblue url("img_tree.gif") no-repeat fixed center' }}>
                    <VideoPlayer videos={mediaData} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

      </div>

    </ >

  );
}

export default StartupIndia;