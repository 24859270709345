import React, { useState } from "react";
import Header from "../../Componets/Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import './styles.css'
import { useEffect } from "react";
import BasicTable from "../../Componets/basicTable";
const MarketAccess = (props) => {
  const columns = [{ name: 'srno', label: 'S.No.' },
  { name: 'organizer', label: 'Organizer' },
  { name: 'eventName', label: 'Event Name' },
  { name: 'impDate', label: 'Imp. Date' },
  { name: 'place', label: 'Place' },
  { name: 'links', label: 'Links' }]

  const rows = [{
    srno: "1",
    organizer: "INC42",
    eventName: "The Makers Summit 2023",
    impDate: "24th & 25th March 2023",
    place: "",
    links: "https://themakerssummit.co/#Tickets"
  }, {
    srno: "2",
    organizer: "NXP Semiconductors",
    eventName: "NXP INDIA TECH STARTUP CHALLENGE 2023",
    impDate: "Apply by 3rd March 2023, Finals in April 2023",
    place: "",
    links: "https://www.startupindia.gov.in/content/sih/en/ams-application/application-listing.html"
  }, {
    srno: "3",
    organizer: "Dell Technologies",
    eventName: "Startup Challenge",
    impDate: "",
    place: "",
    links: "https://www.startupindia.gov.in/content/sih/en/ams-application/application-listing.html"
  }, {
    srno: "4",
    organizer: "Qofunder Pvt Ltd",
    eventName: "WORLD START-UP CONVENTION 2023",
    impDate: "24-25th March 2023",
    place: "India Expo Mart New Delhi",
    links: "https://www.worldstartupconvention.com/"
  }, {
    srno: "5",
    organizer: "3x events, Mojo Startup, Change Catalyst & भारत Entrepreneurs' Conclave",
    eventName: "Global Startup Summit 2023",
    impDate: "18th March 2023",
    place: "Bengaluru",
    links: "hhttps://www.globalstartups.club/"
  }, {
    srno: "6",
    organizer: "New Delhi Entrepreneur and Startup Network",
    eventName: "New Delhi's Big Business Tech & Entrepreneur Professional Networking Affair",
    impDate: "22nd May 2023",
    place: "New Delhi",
    links: "https://www.eventbrite.com/e/new-delhis-big-business-tech-entrepreneur-professional-networking-affair-tickets-140043603187"
  }, {
    srno: "7",
    organizer: "Franchise India Holdings Limited",
    eventName: "Startup Summit,New Delhi-2023",
    impDate: "",
    place: "",
    links: "https://www.tradeindia.com/tradeshows/117734/startup-summit-new-delhi-2023.html"
  }, {
    srno: "8",
    organizer: "brands@globalstartups.club; partner@globalstartups.club",
    eventName: "GLOBAL STARTUPS CLUB l STARTUP NETWORKING",
    impDate: "2nd March 2023",
    place: "Mumbai",
    links: "https://insider.in/global-startups-club-l-mumbai-mar2-2023/event"
  }, {
    srno: "9",
    organizer: "IIT Madras",
    eventName: "Entrepreneurship Summit",
    impDate: "7-9th April 2023",
    place: "Chennai, Tamil Nadu",
    links: "https://www.knowafest.com/explore/events/2023/02/0908-e-summit-2023-iit-madras-entrepreneurship-chennai"
  }];
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container p-5 page-content">
          <h2 className="text-start fw-bold">Startup Programs and Challenges</h2>
          <div className="row aligned-center">
            <div className="col-sm-12">
              <BasicTable rows={rows} columns={columns} />
            </div>
          </div>

        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default MarketAccess;
