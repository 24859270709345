import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './styles.css'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const AboutPage = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Update the URL hash without reloading the page
    window.history.replaceState(null, null, `#${newValue}`);
  };
  const handleHashChange = () => {
    const hash = window.location.hash;
    if (hash) {
      const tabIndex = parseInt(hash.replace("#", ""), 10);
      if (!isNaN(tabIndex)) {
        setValue(tabIndex);
      }
    }
  };

  useEffect(() => {
    handleHashChange(); // Check the hash when the component mounts
    window.addEventListener("hashchange", handleHashChange); // Listen for hash changes

    return () => {
      window.removeEventListener("hashchange", handleHashChange); // Cleanup on unmount
    };
  }, []);

  return (
    <>

      <div className="container p-5 page-content">
        <h2 className="text-start fw-bold">ABOUT US</h2>
        <div className="row mt-3">
          <div className="col-sm-2 p-0 m-0 border-end">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
            >
              <Tab label="About BIPARD" className="w-100" {...a11yProps(0)} />
              <Tab label="Vision" className="w-100" {...a11yProps(1)} />
              <Tab label="Mission" className="w-100" {...a11yProps(2)} />
              <Tab label="Objective" className="w-100" {...a11yProps(3)} />
              <Tab label="General Council" className="w-100" {...a11yProps(4)} />
              <Tab label={<>Memorandum of<br />Association</>} className="w-100" {...a11yProps(5)} />
              
            </Tabs>
          </div>
          <div className="col-sm-10">
            <TabPanel value={value} index={0}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>Introduction</h1></div>
          <p>
            The <b>Bihar Institute of Public Administration and Rural Development</b> is one of the most important regulatory departments of the State
            Government. Subsequent to the formation of Jharkhand State, the
            Administrative Training Institute (ATI) and the State Institute of
            Rural Development (SIRD) by virtue of their location at Ranchi
            vested with the State of Jharkhand.
            <br />
            <br />
            The Officers and the staff of the State of Bihar need to be trained
            in public administration, good governance etc and for that the
            Government through its notification no. 68 dated 28th March 2002
            established the Administrative Training Institute (ATI) at Water and
            Land Management Institute (WALMI), Phulwarisharif, Patna.
            <br />
            <br />
            The State Institute of Rural Development (SIRD) was similarly
            created by the Govt. of Bihar through notification no. 7036 dated
            15/09/2005 and was located at WALMI, Phulwarisharif, Patna along
            with the ATI. The Director General of ATI was designated the
            Director General of SIRD as well.
            <br />
            <br />
            The two institutions has been merged and is now known as BIPARD
            which has now been registered under the Societies Act, 1860 with
            effect from 1st April, 2006. BIPARD conducts training for Indian
            Administrative Services (probationers), probationers of Bihar
            Administrative Services and probationers of other allied services
            who undergo a basic training course at the Institute. As a part of
            the training process, many important subjects are covered like
            Departmental proceedings, Pensions and other benefits, Drawing &
            Disbursing Officers role, Right to Information etc. Apart from
            induction courses short courses are also conducted covering
            important functions and areas like communication skill, working in
            team, personal development etc. The Department of Personnel and
            Administrative Reforms , Govt. of India has approved the DTS, DOT,
            TOT etc and identified BIPARD as an institution to conduct these
            prestigious courses.
            <br />
            <br />
            BIPARD is committed to training Government as well as no.-Government
            functionaries in the field of development management. Recently,
            BIPARD has entered into an agreement and signed MOUs and MOAs with
            UNICEF and is in process of signing such agreements with established
            international donor agencies and NGOs. Recently, in collaboration
            with UNICEF, BIPARD successfully held an international workshop on
            "Advisory services for Environmental Management". Emerging areas of
            Government functioning and Governance such as e-Governance, Human
            Rights, Gender Issues, Social Enterprise Management, NGO Development
            forums are also being developed within the Institute to sensitize
            and propagate better practices within the State of Bihar.
            <br />
            <br />
            Lastly, the Panchayati Raj Institute and the training of the
            recently elected representatives of the Panchayats is being taken up
            by BIPARD on behalf of the Government which will entail training
            1600 Master Trainers, who subsequently will train at the Block level
            to 2.65 lakhs Panchayat Representatives.
            <br />
            <br />
            The use of technology and better assistance in training bringing
            about the latest in good governance is at the best of all efforts
            being made within BIPARD.
            <br />
            <br />
            <h4>Training Programs and Comprehensive Curriculum</h4>
            BIPARD's unwavering commitment to excellence is reflected in its
            diverse curriculum, covering crucial subjects such as Departmental
            proceedings, Pensions and other benefits, Drawing & Disbursing
            Officers' roles, and Right to Information. In addition to induction
            courses, BIPARD conducts short courses addressing vital functions
            like communication skills, teamwork, and personal development.
            Endorsing our commitment, the Government of India, through the
            Department of Personnel and Administrative Reforms, has authorized
            BIPARD to conduct prestigious courses like DTS, DOT, TOT, among
            others.
            <br />
            <br />
            <h4>International Collaborations and Global Outreach</h4>
            BIPARD extends its impact beyond regional boundaries through
            strategic collaborations. Notably, agreements and Memoranda of
            Understanding (MOUs) with UNICEF, coupled with ongoing negotiations
            with international donor agencies and NGOs, exemplify BIPARD's
            global outreach. Collaborative efforts, such as the successful
            international workshop on "Advisory Services for Environmental
            Management" with UNICEF, underscore BIPARD's dedication to staying
            at the forefront of global best practices.
            <br />
            <br />
            <h4>Pioneering Initiatives in Governance</h4>
            Embracing the dynamic landscape of government functioning and
            governance, BIPARD pioneers initiatives in e-Governance, Human
            Rights, Gender Issues, Social Enterprise Management, and NGO
            Development forums. These initiatives aim to sensitize and propagate
            progressive practices within the State of Bihar.
            <br />
            <br />
            <h4>Panchayati Raj Institute and Grassroots Training</h4>
            BIPARD undertakes a critical role in the Panchayati Raj Institute,
            focusing on the training of recently elected representatives of
            Panchayats. This ambitious undertaking involves the training of 1600
            Master Trainers, who, in turn, will impart knowledge at the Block
            level to an extensive 2.65 lakhs Panchayat Representatives.
            <br />
            <br />
            <h4>Incorporating Technology for Enhanced Training</h4>
            Embracing technological advancements, BIPARD ensures that training
            methodologies evolve with the times. The institute places a strong
            emphasis on the use of technology, ensuring better assistance and
            incorporating the latest trends in good governance. This commitment
            positions BIPARD at the forefront of efforts to provide the most
            effective and contemporary training experiences. Explore the future
            of training excellence with BIPARD.
          </p>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="description-card">

                <div className="heading"><h1>Vision</h1></div>
                <p>1) BIPARD envisions a Bihar where every citizen has access to
                quality public services, opportunities for growth, and a voice
                in governance.</p>
                <p>2) It aspires to be a leading institution in India, renowned for
                its innovative approaches to public administration, rural
                development, and governance excellence.</p>

              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="description-card">
                <div className="heading"><h1>Mission</h1></div>
                <p>1) BIPARD's mission is to provide world-class training, research, and consultancy services to public servants, policymakers, and community leaders in Bihar.</p>
                <p>2) It strives to foster a culture of integrity, professionalism, and innovation among its stakeholders, empowering them to address complex challenges and drive positive change in society.</p>
                <p>2) BIPARD is committed to promoting good governance, sustainable development, and social justice through its various programs, projects, and initiatives.</p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>Objective</h1></div>
                  <p>
            Following are the objectives of the organisation:-
            <ul>
              <li>
                To undertake and assist in the organisation of training and
                study-courses, conferences, seminars and lectures, incorporating
                latest methodologies of training and instruction including
                distance learning methodologies.
              </li>
              <li>
                To undertake, aid, promote and coordinate training and research
                through its own or other agencies including non-governmental
                organisations, UN Agencies, universities and other academic
                institutions and training centres including those established by
                or with the aid of the Government of India or any State
                Government including Government of Bihar.
              </li>
              <li>
                <ol style={{listStyleType: "lower-roman"}}>
                  To establish schools/centres for
                  <li>Study and Orientations,</li>
                  <li>Training and Instruction and</li>
                  <li>
                    Research and Evaluation and undertake such others
                    activities as may be necessary to achieve the objectives of
                    BIPARD.
                  </li>
                </ol>
                <li>
                  To analyse specific problems encountered in the planning and
                  implementation of community development, panchayatiraj and
                  other rural development programmes, disaster management, urban
                  development, land, water management and sanitation etc.,
                  especially those designed to promote the interests of
                  vulnerable sections including women and children and propose
                  solutions there.
                </li>
              </li>
              <li>
                To follow up in a manner to be mutually agreed upon, the
                training programmes organised by the Training Centres referred
                to in (b) above and to advise the Central Government and State
                Government and, subject to mutual agreement, any other
                Ministries or authorities on their training programmes.
              </li>
              <li>
                To prepare, print and publish research papers, periodicals and
                books in furtherance of the objectives of the Society.
              </li>
              <li>
                To establish and maintain Libraries and information services,
                and information clearing house in all matters relating to areas
                mentioned in (d) above. to collaborate with other Institutions
                including UN Agencies, Associations and Societies in India and
                in the State of Bihar, in particular and abroad interested in
                similar objectives.
              </li>
              <li>
                To offer Fellowships, Scholarships, Prize and Stipends in
                furtherance of the objectives of BIPARD.
              </li>
              <li>
                To promote application of science and technology in the
                development of rural areas through training and research. BIPARD
                is an apex Institute of Training and Research in the field of
                Public Administration, Rural Development, Disaster Management,
                Panchayati Raj, Non-Government Organisation, Urban Development,
                Land, Water Management and Sanitation etc. It has been developed
                as a centre of excellence to provide the necessary support to
                the National and State governments and other agencies concerned
                in these fields in the areas of policy formulation, programme
                initiatives, implementation strategies, training, research
                evaluation, documentation and dissemination of information.
              </li>
            </ul>
          </p>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>General Council</h1></div>
                  <p align="justify"><strong>The General
                    Council of the Institute shall be the governing body of the Institute as
                    defined in Section 16 of Societies Registration Act, 1860. It shall consist
                    of the following:</strong></p>

                  <table className="table table-striped table-bordered">
                    <tbody><tr>
                      <td width="59" valign="top" align="left">1.</td>
                      <td valign="top" align="left">Chief Secretary to the Government
                        of Bihar </td>
                      <td valign="top" align="left">President</td>
                    </tr>
                      <tr>
                        <td width="59" valign="top" align="left">2.</td>
                        <td valign="top" align="left">Commissioner &amp; Secretary,
                          Planning and Development/ Development Commissioner, Bihar, Patna</td>
                        <td valign="top" align="left">Vice President</td>
                      </tr>
                      <tr>
                        <td width="59" valign="top" align="left">3.</td>
                        <td valign="top" align="left">Commissioner cum Secretary/
                          Principal Secretary Finance Department, Bihar, Patna</td>
                        <td valign="top" align="left">Vice President</td>
                      </tr>
                      <tr>
                        <td width="59" valign="top" align="left">4.</td>
                        <td valign="top" align="left"><p>Secretary / Commissioner
                          cum Secretary/ Principal Secretary of the Department of Govt. of Bihar
                          of Department of Personnel and </p>
                          <p>Department of Rural Development </p></td>
                        <td valign="top" align="left">Ex-officio Members</td>
                      </tr>
                      <tr>
                        <td width="59" valign="top" align="left">5.</td>
                        <td valign="top" align="left"><p>Secretaries/Commissioner-cum-Secretaries
                          /Principal Secretaries of such other departments of the Government
                          of Bihar as the</p>
                          <p>state Government may like to nominate</p></td>
                        <td valign="top" align="left">Members</td>
                      </tr>
                      <tr>
                        <td width="59" valign="top" align="left">6.</td>
                        <td valign="top" align="left">Director/ Director General
                          of Administrative Training Institute/BIPARD as described in rule 7</td>
                        <td valign="top" align="left">Ex-officio Member Secretary</td>
                      </tr>
                    </tbody>
                  </table>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>Memorandum of Association</h1></div>
                  <p>
                    <p><b>Bihar
                      Institute of Public Administration and Rural Development(BIPARD)</b></p>

                    <p><b>1. Name of the Society</b></p>
                    <p>The name of the Society will be “Bihar Institute of Public Administration and
                      Rural Development” (hereinafter referred to as BIPARD)</p>
                    <p><b>2. Location and Registered office of the Society</b></p>
                    <p>The registered office of the Society shall be at WALMI Campus, Patna-801505
                      the state of Bihar.</p>
                    <p><b>3. Objectives of the Society </b></p>
                    <p>BIPARD will be an apex Institute of Training and Research in the field of Public
                      Administration, Rural Development, Disaster Management, Panchayati Raj, Non-Government
                      Organisations, Urban Development, Land and Water Management, etc. It will be
                      developed as a centre of excellence to provide the necessary support to the
                      national and state governments and Government of Bihar, in particular, and other
                      agencies concerned in these fields in the areas of policy formulation, programme
                      initiatives, implementation strategies, training, research evaluation, documentation
                      and dissemination of information in India.</p>
                    <p>The objects for which the Society is formed are to provide for and promote
                      study in the fields mentioned above, in India and State of Bihar, in particular,
                      and on global scale and with this purpose;</p>
                    <p>(a) to undertake and assist in the organisation of training and study courses,
                      conferences, seminars and lectures, incorporating latest methodologies of training
                      and instruction including distance learning methodologies;</p>
                    <p>(b) to undertake, aid, promote and coordinate training, research and action
                      research through its own or other agencies including non-governmental organisations,
                      universities and other academic institutions and training centres including
                      those established by/ with the aid of the Government of India or any State Government
                      including Government of Bihar;</p>
                    <p>(c) to establish schools/centres for (i) Study and orientations, (ii) Training
                      and instruction, and (iii) Research and Evaluation, and such others activities
                      as may be necessary to achieve the objects of the Society;</p>
                    <p>(d) to analyse specific problems encountered in the planning and implementation
                      of community development, panchayatiraj and other rural development programmes,
                      disaster management, urban development, land and water management, etc., especially
                      those designed to promote the interests of vulnerable section including women,
                      and propose solutions thereto;</p>
                    <p>(e) to follow up in a manner to be mutually agreed upon, the training programmes
                      organised by the Training Centres referred to in (b) above and to advise the
                      Central Government and Government of Bihar and, subject to mutual agreement,
                      any other Ministries or authorities on their training programmes;</p>
                    <p>(f) to prepare, print and publish papers, periodicals and books in furtherance
                      of the objects of the Society;</p>
                    <p>(g) to establish and maintain Libraries and information services, and information
                      clearing house in all matters relating to areas mentioned above at the beginning
                      of para-3.</p>
                    <p>(h) to collaborate with other institutions, Associations and Societies in India
                      and in the State of Bihar, in particular and abroad interested in similar objects;</p>
                    <p>(i) to offer Fellowships, Scholarships, Prize and Stipends in furtherance of
                      the objects of the Society; and</p>
                    <p>(j) to promote application of science and technology in the development of
                      rural areas through training research and action research.
                    </p>
                    <p><b>4. Pursuant to the aforesaid objects, the Society
                      may :</b></p>
                    <p>(a) accept grants of money, securities or property of any kind and undertake
                      and accept the management of any endowment, trust, fund or donation not inconsistent
                      with the objects of the Society, on such terms as may be prescribed by the Government
                      of Bihar from time to time;</p>
                    <p>(b) invest and deal with funds and monies of the Society;</p>
                    <p>(c) impose and recover fees and charges for the services rendered by it;</p>
                    <p>(d) purchase, hire, take on lease, exchange or otherwise acquire a property
                      movable or immovable, which may be necessary or convenient for the purpose of
                      the Society and construct, alter and/or maintain such buildings and works, as
                      may be necessary for carrying out the objects of the Society;</p>
                    <p>(e) sell, hire, lease, exchange or otherwise transfer or dispose of all or
                      any property, movable or immovable, of the society, provided that for the transfer
                      of immovable property prior approval in writing of the Government of Bihar will
                      be obtained;</p>
                    <p>(f) borrow and raise monies on mortgages, promissory notes or other securities
                      founded or based upon all or any of the properties and assets of the Society
                      or without any securities, provided that prior approval in writing of the Government
                      of Bihar will be obtained in that behalf;</p>
                    <p>(g) draw, accept, make endorse, discount and deposit government of India and
                      other promissory notes, bills of exchange, cheques or other negotiable instruments;</p>
                    <p>(h) create Reserve Fund, Sinking Fund, Insurance Fund or any other special
                      fund whether for depreciation, repairs improvement, extension or maintenance
                      of any of the properties or rights of the society and/or for recouping wasting
                      assets and for any other purposes for which the society deems it expedient or
                      proper to create or maintain any such fund or funds;</p>
                    <p>(i) create administrative, technical, ministerial and other posts in Society,
                      and make appointments thereto in accordance with the Rules and Regulations of
                      the Society.</p>
                    <p>(j) establish a Provident Fund for the benefit of the staff of the Society
                      or any sections thereof;</p>
                    <p>(k) make rules and bye-laws for the conduct of the affairs of the society and
                      add to, amend vary or rescind them from time to time;</p>
                    <p>(l) do all such other acts an things either alone of in conjunction with other
                      organisations or persons as the Society may consider necessary incidental or
                      conductive to the attainment of the above objects.
                    </p>
                    <p><b>5. Governing Council</b></p>
                    <p>The names, occupations, and addresses of the first members of the General Council
                      (Governing Body) to whom, by the rules of the society the management of its
                      affairs is entrusted, are as follows:-</p>
                    <table className="table table-striped table-bordered table-hover">
                      <tbody><tr>
                        <td width="17" ><b>SL</b></td>
                        <td width="402"><b>Name, Occupation &amp; Address</b></td>
                        <td width="106"><b>Designation</b></td>
                      </tr>
                        <tr>
                          <td> 1.</td>
                          <td>Shri Amir Subhani, I.A.S,
                            Chief Secretary, Main Secretariat, Patna-800015</td>
                          <td>
                            Chairman</td>
                        </tr>
                        <tr>
                          <td> 2.</td>
                          <td>Shri Vivek Kumar
                            Singh, I.A.S,
                            Development Commissioner, Main Secretariat, Patna-800015</td>
                          <td>
                            Vice Chairman</td>
                        </tr>
                        <tr>
                          <td> 3.</td>
                          <td>Shri K.K. Pathak,
                            I.A.S, Director General, ATI, WALMI Complex, Patna-801505</td>
                          <td>Ex-officio<br /> Member Secretary</td>
                        </tr>
                        <tr>
                          <td> 4.</td>
                          <td>Shri S.Siddharth,
                            Additional Chief Secretary, Finance Department, Main Secretariat, Patna-800015</td>
                          <td>
                            Vice Chairman</td>
                        </tr>
                        <tr>
                          <td> 5.</td>
                          <td>Shri B. Rajendra, I.A.S,
                            Principal
                            Secretary, Personnel and Administrative Reforms Department, Main Secretariat,
                            Patna-800015</td>
                          <td>
                            Member</td>
                        </tr>
                        <tr>
                          <td> 6.</td>
                          <td>Shri Sanjay Kumar
                            Agarwal,
                            I.A.S, Secretary, Disaster Management Department, Govt. of Bihar</td>
                          <td>
                            Member</td>
                        </tr>
                        <tr>
                          <td> 7.</td>
                          <td>Shri Balamurgan D, I.A.S,
                            Commissioner and Secretary, Rural Development Department, Main Secretariat,
                            Patna-800015</td>
                          <td> Ex-officio Member</td>
                        </tr>
                      </tbody>
                    </table>
                    <p><b>6. All property of the Society, movable or immovable
                      shall vest in the Executive Council.</b></p>
                    <p><b>7.</b><b> The income
                      and property of the Society howsoever derived, shall be applied towards the
                      promotion of the objects as set for the in this Memorandum of Association subject,
                      nevertheless, in respect of expenditure of grants made by the Government of
                      India and State Governments to such direction as the Government of India or
                      such State Government may from time to time give. No portion of the income and
                      property of the Society shall be paid or transferred directly or indirectly
                      by way of dividend, bonus or otherwise howsoever by way of profit to persons
                      who at any time are or have been members of the society, or to any of them or
                      to any persons claiming through them or any them, provided that nothing herein
                      shall prevent the payment in good faith of remuneration to any member or other
                      person in return for service rendered to the Society or for travelling allowance,
                      halting allowance and other similar charges.</b></p>
                    <p><b>8. If on the winding up or dissolution of the Society,
                      there shall remain after the satisfaction of its debts and liabilities any property
                      whatsoever, the same shall not be paid to or distributed among the members of
                      the Society or any of them, but shall be dealt with in the manner provided by
                      section 14 &amp; 14A of the Societies Registration Act of 1860 (Act No.21 of
                      1860)</b></p>
                    <p><b>9. A copy of the Rules and Regulations of the Society
                      certified to be correct by three members of the General Council (Governing Body)
                      is filed with the Registrar of Societies, Bihar, Patna along with the Memorandum
                      of Association.</b></p>
                    <p> We, the several members whose name and addresses are given below, having associated
                      ourselves for the purpose prescribed in this Memorandum of Association and set
                      our several and respective hands here unto and form ourselves into a Society
                      under the Societies Registration Act of 1860 (Act no.21 of 1860) this …………..
                      day of …………….. at Patna.</p>
                    <table className="table table-striped table-bordered table-hover">
                      <tbody><tr>
                        <td width="16">
                          <b>SL</b></td>
                        <td width="509">
                          <b>Name and Occupation</b></td>
                        <td width="265">
                          <b>Designation</b></td>
                        <td width="65">
                          <b>Signature</b></td>
                      </tr>
                        <tr>
                          <td> 1.</td>
                          <td>Shri Amir Subhani, I.A.S,
                            Chief Secretary, Govt. of Bihar, Patna</td>
                          <td>
                            Chairman</td>
                          <td>Sd/-</td>
                        </tr>
                        <tr>
                          <td> 2.</td>
                          <td>Shri Vivek Kumar
                            Singh, I.A.S,
                            Development Commissioner, Govt. of Bihar, Patna</td>
                          <td>
                            Vice Chairman</td>
                          <td>Sd/-</td>
                        </tr>
                        <tr>
                          <td> 3. </td>
                          <td>Shri K.K. Pathak,
                            I.A.S, Director General, ATI, WALMI Complex, Patna-801505</td>
                          <td>
                            Ex-officio Member</td>
                          <td>Sd/-</td>
                        </tr>
                        <tr>
                          <td> 4.</td>
                          <td>Shri S.Siddharth,
                            Commissioner and Secretary, Finance Department, Govt. of Bihar, Patna</td>
                          <td>
                            Vice Chairman</td>
                          <td>Sd/-</td>
                        </tr>
                        <tr>
                          <td> 5.</td>
                          <td>Shri B. Rajendra, I.A.S,
                            Princiapal Secretary, Personnel and Administrative Reforms Department, Govt. of Bihar, Patna</td>
                          <td>
                            Member</td>
                          <td>Sd/-</td>
                        </tr>
                        <tr>
                          <td> 6.</td>
                          <td>Shri Sanjay Kumar
                            Agarwal,
                            I.A.S, Secretary, Disaster Management Department, Govt. of Bihar, Patna</td>
                          <td>
                            Member</td>
                          <td>Sd/-</td>
                        </tr>
                        <tr>
                          <td> 7.</td>
                          <td>Shri Balamurgan D, I.A.S,
                            Commissioner and Secretary, Rural Development Department, Govt. of Bihar,
                            Patna</td>
                          <td>
                            Ex-officio Member</td>
                          <td>Sd/-</td>
                        </tr>
                      </tbody>
                    </table>
                  </p>
                </p>
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
