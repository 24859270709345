import Header from "../Componets/Header/Header";
import StartupIndiaFooter from "../Componets/startupIndiaFooter/index";


const Layout = (props) => {
    return (
        <div style={{ width: "100%", color: "black" }}>
            <Header />
            {props.children}
            <StartupIndiaFooter />
        </div>
    )
}

export default Layout;