import React, { useState } from "react";
import Header from "../Componets/Header/Header";
import StartupIndiaFooter from "../../src/Componets/startupIndiaFooter";
// import './styles.css'
const IncubatorCenter = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "white", width: "100%", color: "black", }}>
        <Header />
        <div className="container p-5">
          <h2 className="text-start fw-bold">List of Incubation Centre for Start-up</h2>
          <div className="row aligned-center ">
            <div className="col-sm-12">
              <div className="">
                <p style={{ textAlign: 'left' }}>

                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="col-sm-10 text-end " style={{ marginRight: "18%" }}>
              {/* <img src={recognition} className="w-100" /> */}
              <table className="bg-white shadow table table-stripped table-hover rounded-3" style={{ border: '1px solid black', textAlign: 'center', color: '#144585', height: "30vh", width: "120%" }}>
                <tr style={{ backgroundColor: 'rgb(33 158 180 / 80%)', color: 'black' }}>
                  <th>S.No</th>
                  <th>Name of Incubation Centre</th>
                  <th>Website Address</th>

                </tr>
                <tr>
                  <td>1</td>
                  <td>Indian Institute of Technology (IIT), Patna </td>
                  <td>
                    <a href="https://iciitp.com/">https://iciitp.com/</a>

                  </td>

                </tr>

                <tr>
                  <td>2</td>
                  <td>National Institute of Technology (NIT), Patna </td>
                  <td>
                    <a href="https://www.nitp.ac.in/">https://www.nitp.ac.in/</a>

                  </td>

                </tr>


                <tr>
                  <td>3</td>
                  <td>Chandragupta Institute of Management (CIMP) Patna  </td>
                  <td>
                    <a href="http://cimpstartup.com/">http://cimpstartup.com/</a>

                  </td>

                </tr>

                <tr>
                  <td>4</td>
                  <td>National Institute of Fashion Technology (NIFT), Patna   </td>
                  <td>
                    <a href="https://www.nift.ac.in/patna/IncubationCell">https://www.nift.ac.in/patna/IncubationCell</a>

                  </td>

                </tr>

                <tr>
                  <td>5</td>
                  <td> Birla Institute of Technology (BIT), Patna   </td>
                  <td>
                    <a href="https://www.bitmesra.ac.in/">https://www.bitmesra.ac.in/</a>

                  </td>

                </tr>

                <tr>
                  <td>6</td>
                  <td> Bhagalpur Agriculture university (BAU), SABOUR, Bhagalpur    </td>
                  <td>
                    <a href="https://bausabour.ac.in/incubation-center.aspx">https://bausabour.ac.in/incubation-center.aspx</a>

                  </td>

                </tr>
                <tr>
                  <td>7</td>
                  <td> Development Management Institute (DMI), Patna     </td>
                  <td>
                    <a href="https://www.dmi.ac.in/">https://www.dmi.ac.in/</a>

                  </td>

                </tr>

                <tr>
                  <td>8</td>
                  <td> Development Management Institute (DMI), Patna     </td>
                  <td>
                    <a href="https://www.dmi.ac.in/">https://www.dmi.ac.in/</a>

                  </td>

                </tr>

                <tr>
                  <td>10</td>
                  <td> TOOL ROOM & TRAINING CENTRE (TRTC)    </td>
                  <td>
                    <a href="https://patna.idtr.gov.in/index.php">https://patna.idtr.gov.in/index.php</a>

                  </td>

                </tr>

                <tr>
                  <td>11</td>
                  <td> Upendra Maharathi Shilp Anusandhan Sansthan (UMSAS), Patna </td>
                  <td>
                    <a href="https://umsas.org.in/">https://umsas.org.in/</a>

                  </td>

                </tr>

                <tr>
                  <td>12</td>
                  <td> Software Technology Park of India (STPI), Patna </td>
                  <td>
                    <a href="https://stpi.in/en/incubation-services">https://stpi.in/en/incubation-services</a>

                  </td>

                </tr>

                <tr>
                  <td>13</td>
                  <td> Sanjay Gandhi Institute of Dairy Technology (SGIDT), Patna </td>
                  <td>
                    <a href="https://basu.org.in/">https://basu.org.in/</a>

                  </td>

                </tr>

                <tr>
                  <td>14</td>
                  <td> Central Institute of Petrochemicals Engineering & Technology (CIPET), Hajipur  </td>
                  <td>
                    <a href="https://www.cipet.gov.in/centres/cipet-hajipur/introduction.php">https://www.cipet.gov.in/centres/cipet-hajipur/introduction.php</a>

                  </td>

                </tr>

                <tr>
                  <td>15</td>
                  <td> Muzaffarpur Institute of Technology (MIT)
                    Muzaffarpur, Bihar  </td>
                  <td>
                    <a href="https://www.mitmuzaffarpur.org/startup-cell/">https://www.mitmuzaffarpur.org/startup-cell/</a>

                  </td>

                </tr>

                <tr>
                  <td>16</td>
                  <td> Dr. Rajendra Prasad Central Agricultural University (PUSA), Samastipur  </td>
                  <td>
                    <a href="https://www.rpcau.ac.in/centre-for-start-up-facilitation/">https://www.rpcau.ac.in/centre-for-start-up-facilitation/</a>

                  </td>

                </tr>

                <tr>
                  <td>17</td>
                  <td> Central University, Motihari   </td>
                  <td>
                    <a href="https://www.mgcub.ac.in/">https://www.mgcub.ac.in/</a>

                  </td>

                </tr>

                <tr>
                  <td>18</td>
                  <td> National Institute of Pharmaceutical Education and Research (NIPER) HAJIPUR  </td>
                  <td>
                    <a href="http://www.niperhajipur.ac.in/Home">http://www.niperhajipur.ac.in/Home</a>

                  </td>

                </tr>

                <tr>
                  <td>19</td>
                  <td> National Institute of Electronics & Information Technology (NIEIT), Patna </td>
                  <td>
                    <a href="https://nielit.gov.in/patna/index.php">https://nielit.gov.in/patna/index.php</a>

                  </td>

                </tr>

                <tr>
                  <td>20</td>
                  <td>Footwear Design and Development Institute (FDDI), Patna</td>
                  <td>
                    <a href="https://fddiindia.com/">https://fddiindia.com/</a>

                  </td>

                </tr>

                <tr>
                  <td>21</td>
                  <td> Indian Institute of Managemant (IIM), Gaya</td>
                  <td>
                    <a href="https://iimbg.ac.in/">https://iimbg.ac.in/</a>

                  </td>

                </tr>

                <tr>
                  <td>22</td>
                  <td> Amity University (AMITY), Patna </td>
                  <td>
                    <a href="https://amity.edu/Bihar/">https://amity.edu/Bihar/</a>

                  </td>

                </tr>

                <tr>
                  <td>23</td>
                  <td> Aryabhatt Knowledge University, Patna </td>
                  <td>
                    <a href="http://akubihar.ac.in/">http://akubihar.ac.in/</a>

                  </td>

                </tr>


              </table>
            </div>

          </div>
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default IncubatorCenter;
