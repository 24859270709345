import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';

const CardCarousel = (props) => {
    const [imagesData, setImagesData] = useState([]);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        if (
            props.galleryResult &&
            props.galleryResult.data &&
            Array.isArray(props.galleryResult.data.data)
        ) {
            const img = props.galleryResult.data.data.map((e) => e.imageLink);
            setImagesData(img);
        }
    }, [props.galleryResult]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    const openLightbox = (index) => {
        setCurrentImageIndex(index);
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    const handleImageClick = (index) => {
        openLightbox(index);
    };

    return (
        <div className="row justify-content-center">
            <div className="col-sm-12">
                <Slider {...settings}>
                    {imagesData.map((image, i) => (
                        <div key={i}>
                            <img
                                src={image}
                                alt={`Image ${i + 1}`}
                                onClick={() => handleImageClick(i)}
                                style={{ cursor: 'pointer', width: '97%', height: '350px', margin: "0px 10px", borderRadius: "10px", objectFit: "cover" }}
                            />
                        </div>
                    ))}
                </Slider>
                {lightboxIsOpen && (
                    <div className="lightbox">
                        <span className="close" onClick={closeLightbox}>&times;</span>
                        <Slider {...settings2} initialSlide={currentImageIndex}>
                            {imagesData.map((image, i) => (
                                <div key={i}>
                                    <img
                                        src={image}
                                        alt={`Image ${i + 1}`}
                                        className="lightbox-content"
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardCarousel;
