import React, { useState } from "react";
import './style.css'
import '../../../assets/css/hover.css'
const IconCard = (props) => {
    return (

        <div className="icon-card hvr-grow">
            <a href={props.links} target="_blank">
                <i className={props.icons}></i>
                <h6>{props.text}</h6>
            </a>
        </div>

    )
}

export default IconCard;