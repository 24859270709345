import React, { useState } from "react";
import Header from "../../Componets/Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import './styles.css'
import psc from './psc.png'
import psc_role from './psc_role.png'
const PSC = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container p-5 page-content">
          <h3 className="text-start fw-bold">Preliminary Scrutiny Committee (PSC)</h3>
          <h5 className="text-start">A Preliminary Scrutiny Committee shall be constituted under the Chairmanship of Director to be notified by the Department of Industries, Government of Bihar.</h5>
          <div className="row aligned-center">
            <div className="col-sm-6">
              <img src={psc} className="w-100" />
            </div>
            <div className="col-sm-6">
              <img src={psc_role} className="w-100" />
            </div>
          </div>
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default PSC;
