import React, { useState } from "react";
import './styles.css'
import TrainingCalendar from "../trainingCalendar";
const TrainingRule = (props) => {
  return (
    <>
      <div className="container p-5 page-content">
        <div className="row aligned-center">
          <div className="col-sm-12">
            <div className="description-card" style={{ overflow: "hidden" }}>
              <p>
                <h2 className="text-start fw-bold">Training Rules</h2>
                <ul style={{fontSize:"20px"}}>
                <li>Always be polite with all Faculty/Staff</li>
                <li>Always inform your Course Co coordinator in case you have to leave in between your Training due to extreme emergency.</li>
                <li>Always follow class rooms announcements properly.</li>
                <li>Always follow Class timings.</li>
                <li>Use of mobile phones inside the classrooms are strictly prohibited</li>
                <li>Trainees should feed their biometric attendance compulsorily</li>
                <li>Trainees must wear their photo identity card at all time inside the campus</li>
<li>Trainees must wear formal clothing during their staying institute.</li>
</ul>
                
                
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingRule;
