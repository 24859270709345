import React, { useState, useEffect } from "react";
import axios from 'axios';
import bipard from '../../assets/img/BIPARD-logo.png'
import './styles.css';
import { Box, TextField, Select, MenuItem, Grid, Checkbox, Typography, Stack, Button, Modal, Paper } from '@mui/material';
import { Label } from "@mui/icons-material";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { addUserFeedback, feedbackOtp } from '../basicAction/index'
import CountUp from 'react-countup';
import { actionNotifier } from "../notification";
import { loaderEnd, loaderStart } from "../loader";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';



const initialValue1 = {
  fullName: "",
  mobileNumber: "",
  email: "",
  isRegisteredInBiharStartup: "",
  message: "",
  registrationNo: "",
  fileUpload: "",
  otp: ""
};

const StartupIndiaFooter = ({ props }) => {
  const dispatch = useDispatch();
  const modalFlag = JSON.parse(localStorage.getItem('grievence'));

  //const [count, setCount] = useState();
  //const [ipData, setIPData] = useState();
  const [modalRegister, setModalRegister] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalOtp, setModalOtp] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalEmailError, setEmailError] = useState(false);
  const [checkedNew, setCheckedNew] = useState(false);
  const [checkedOld, setCheckedOld] = useState(false);
  const [state, setState] = useState([initialValue1]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: "auto",
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
    marginTop: "35px"
  };

  const style4 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: "auto",
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
    marginTop: "35px"
  };
  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/')
  //   setIPData(res?.data?.IPv4)
  // }
  // useEffect(() => {
  //   getData()
  // }, [])
  // const url = "https://startup.bihar.gov.in/api/v1/account/visitor-count";

  // useEffect(() => {
  //   axios.post(url, { ipAddress: ipData })
  //     .then(res => {
  //       //console.log(res);
  //       setCount(res?.data?.data);
  //     })
  //   // const requestOptions = {
  //   //   method: 'POST',
  //   //   body: JSON.stringify({ ipAddress: ipData })
  //   // };
  //   // fetch(url, requestOptions)
  //   //   .then(response => response.json())
  //   //   .then(data => setCount(data.count));
  //   if (modalFlag == true) {
  //     setModalRegister(true)
  //     dispatch(modalGrievence(false))
  //   }


  // }, [ipData, modalFlag]);
  console.log(localStorage.getItem('grievence'));
  return (
    <>
      <div className="footer-startup p-3 w-100" style={{ backgroundColor: "#000869", color: "#fff", fontSize: "12px" }}>
        {/* <a href="javascript:void(0)" className="count">Visitors : <CountUp end={count + 100000} /></a> */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-2 text-right-important">
                  <img src={bipard} className="mt-3 w-50" />
                </div>
                <div className="col-sm-3 text-left-important" style={{ borderLeft: "1px solid black", textAlign: "left !important" }}>
                  <br />
                  <h6>Bihar Institute of Public Administration<br /> & Rural Development</h6>
                </div>
                <div className="col-sm-3 connect-with-us text-left-important">
                  <h6>Important Links</h6>
                  <a target="_blank" href="https://state.bihar.gov.in/rdd/CitizenHome.html">Rural Development Department</a><br />
                  <a target="_blank" href="https://jaankari.bihar.gov.in/">JAANKARI</a><br />
                </div>
                <div className="col-sm-3 connect-with-us text-left-important">
                  <h6>Quick Links</h6>
                  <a target="_blank" href="/contacts">Contacts</a><br />
                  <a target="_blank" href="http://bpsm.bihar.gov.in/assets/Home.aspx?P1=3&P2=0&P3=79&P4=3">Asset Declaration</a><br />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-startup p-1" style={{ backgroundColor: "#af2910", color: "#fff", fontSize: "12px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-10 text-center">
              <div className="row">
                <div className="col-sm-6">
                  <i style={{ fontSize: "12px" }} className="fa fa-copyright"></i> Copyright owned by <b>BIPARD,</b> All Rights Reserved.
                </div>
                <div className="col-sm-6">
                  Developed by: <a className="text-decoration-none text-white" href="https://codebuckets.in/" target="_blank">Codebucket Solutions Pvt. Ltd.</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default StartupIndiaFooter;
