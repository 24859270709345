import React, { useState, useEffect } from "react";
import './style.css'
const BasicTable = (props) => {
    // console.log(props)
    const [allLabels, setAllLabels] = useState([])
    useEffect(() => {
        if (props.rows && props.rows.length > 0) {
            let allLabels = props.columns.map((c) => { return c.name });
            // console.log(allLabels);
            setAllLabels(allLabels)
        }
    }, [props.rows])

    // console.log(props.columns);
    return (
        <>

            <h4>{props?.title}</h4>
           
            <table className="bg-white shadow table table-stripped table-hover rounded-5 mt-3" style={{ border: '1px solid black', textAlign: 'left', color: '#144585',width:'100%'}}>
                <thead>
                    <tr>
                        {
                            props?.columns?.map((c, i) => (
                                <th className="table-header" key={i}><center>{c.label}</center></th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props?.rows && props?.rows?.length > 0 && props?.rows?.map((r, i) => (
                            <tr>
                                {
                                    allLabels && allLabels?.length > 0 && allLabels?.map((a, j) => (
                                      <td>
                                           <center>   {a == 'links' ? r[a] ? <a target="_blank" href={r[a]}>View</a> : "---" : r[a]}</center>
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table >
         
        </>
    );
};

export default BasicTable;
