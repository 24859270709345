import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import './style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getDGReview } from "../../Componets/basicAction/index";
import { dGReviewFilter } from "../../Componets/basicAction/index";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { dateFormat } from "../../shared/dateFunction";
function DGReview() {
  const [openModal, setOpenModal] = useState(false);
  const [pptxUrl, setPptxUrl] = useState('');
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (url) => {
    setPptxUrl(url);
    setOpenModal(true);
  };
  const [dGReviewData, setDGReviewData] = useState([]);

  const onGetDGReview = async () => {
    let data = await getDGReview(dGReviewFilter);
    if (data.status) {
      setDGReviewData(data.data.data);
    }
  };

  useEffect(() => {
    onGetDGReview();
  }, []);
  console.log(dGReviewData);
  const groupedGalleries = dGReviewData.reduce((grouped, gallery) => {
    const date = new Date(gallery.uploadedDate);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    if (!grouped[formattedDate]) {
      grouped[formattedDate] = [];
    }
    grouped[formattedDate].push(gallery);
    return grouped;
  }, {});
  console.log(groupedGalleries);
  return (
    <>
      <Dialog open={openModal} onClose={handleCloseModal} style={{ zIndex: "99999" }} maxWidth="xl" // Set maxWidth to extra large
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: 'none',
          },
        }}>
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          PPTX Viewer
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "95%" }}>
          <iframe
            title="PPTX Viewer"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(pptxUrl)}`}
            width="100%"
            height="500px"
          ></iframe>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions> */}
      </Dialog>
      <div className="container p-5 page-content">
        <h3 className="text-start fw-bold">DG Review</h3>
        <div className="col-sm-12">
          <div className="upload_image_Section">
            {Object.keys(groupedGalleries).map((date) => (
              <div key={date}>
                <br /><h5>{dateFormat(date)}</h5><br />
                <div className="uploaded_images">
                  {groupedGalleries[date].map((gallery) => (
                    <div className="uploaded_image" key={gallery.dgReviewId}>

                      {gallery.fileLink.toLowerCase().endsWith('.pdf') && (
                        <object
                          style={{ borderRadius: "10px" }}
                          data={`https://docs.google.com/viewer?url=${encodeURIComponent(gallery.fileLink)}&embedded=true`}
                          type="application/pdf"
                        >
                          <p>It appears you don't have a PDF plugin for this browser. You can <a href={gallery.fileLink}>click here to download the PDF file</a>.</p>
                        </object>
                      )}
                      {gallery.fileLink.toLowerCase().endsWith('.pptx') && (
                        <iframe
                          style={{ borderRadius: "10px" }}
                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(gallery.fileLink)}`}
                        ></iframe>
                      )}

                      {!gallery.fileLink.toLowerCase().endsWith('.pdf') &&
                        !gallery.fileLink.toLowerCase().endsWith('.pptx') && (
                          <img src={gallery.fileLink} alt="No Image" />
                        )}
                      <br /><a href="#" onClick={() => handleOpenModal(gallery.fileLink)}>View</a>

                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ >
  );
}

export default DGReview;
