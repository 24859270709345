import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import './style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getDGReview } from "../../Componets/basicAction/index";
import { dGReviewFilter } from "../../Componets/basicAction/index";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import april from '../../assets/img/april.png';
import may from '../../assets/img/may.png';
import junejuly from '../../assets/img/junejuly.png';
import march from '../../assets/img/march.png';
import marchpdf from '../../assets/pdf/Unnayan March 2024.pdf'
import aprilpdf from '../../assets/pdf/Unnayan April.pdf'
import maypdf from '../../assets/pdf/Unnayan May.pdf'
import junejulypdf from '../../assets/pdf/Unnayan June.pdf'
import { dateFormat } from "../../shared/dateFunction";
function Magazine() {
  const [openModal, setOpenModal] = useState(false);
  const [pptxUrl, setPptxUrl] = useState('');
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (url) => {
    setPptxUrl(url);
    setOpenModal(true);
  };
  const [dGReviewData, setDGReviewData] = useState([
    {
      "id": 34,
      "dgReviewId": 34,
      "fileLink": "https://erp.bipard.co.in/api/uploads/55/2024_01_12t06_53_18_725z.pptx",
      "uploadedDate": "April 2024",
      "updatedBy": 55,
      "updatedDate": "April 2024",
      "status": "Active"
    },
    {
      "id": 33,
      "dgReviewId": 33,
      "fileLink": "https://erp.bipard.co.in/api/uploads/55/2024_01_12t06_52_50_829z.pptx",
      "uploadedDate": "May 2024",
      "updatedBy": 55,
      "updatedDate": "May 2024",
      "status": "Active"
    },
    {
      "id": 29,
      "dgReviewId": 29,
      "fileLink": "https://erp.bipard.co.in/api/uploads/54/2023_12_14t16_24_05_163z.pptx",
      "uploadedDate": "June 2024",
      "updatedBy": 54,
      "updatedDate": "June 2024",
      "status": "Active"
    }
  ]);

  // const onGetDGReview = async () => {
  //   let data = await getDGReview(dGReviewFilter);
  //   if (data.status) {
  //     setDGReviewData(data.data.data);
  //   }
  // };


  console.log(dGReviewData);

  return (
    <>
      <Dialog open={openModal} onClose={handleCloseModal} style={{ zIndex: "99999" }} maxWidth="xl" // Set maxWidth to extra large
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: 'none',
          },
        }}>
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          PPTX Viewer
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "95%" }}>
          <iframe
            title="PPTX Viewer"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(pptxUrl)}`}
            width="100%"
            height="500px"
          ></iframe>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions> */}
      </Dialog>
      <div className="container p-5 page-content">
        <h3 className="text-start fw-bold">Magazines</h3>
        <div className="row">
        <div className="col-sm-4">
          <div className="magzine_Section">
            <div>
              <br /><h5>March 2024</h5><br />
              <a href={marchpdf} target="_blank"><img style={{width:"100%"}} src={march} /></a>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="magzine_Section">
            <div>
              <br /><h5>April 2024</h5><br />
              <a href={aprilpdf} target="_blank"><img style={{width:"100%"}} src={april} /></a>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="magzine_Section">
            <div>
              <br /><h5>May 2024</h5><br />
              <a href={maypdf} target="_blank"><img style={{width:"100%"}} src={may} /></a>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="magzine_Section">
            <div>
              <br /><h5>June-July 2024</h5><br />
              <a href={junejulypdf} target="_blank"><img style={{width:"100%"}} src={junejuly} /></a>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default Magazine;
