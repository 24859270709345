import axios from "axios";
import { REACT_APP_API_URL } from "../../shared/constant";


export const getToken = () => {
    let token = localStorage.getItem("bipard-token")
    token = JSON.parse(token)
    if (token) {
        return token.token
    }
    return '';
}


export const apiFunction = async (url, method, postData, token, extraConfig) => {
    console.log(process.env.REACT_APP_API_URL, url)
    url = process.env.REACT_APP_API_URL + url
    let config = {
        method: method,
        url: url,
        data: postData ? postData : {},
    };
    if (token) {
        let token = getToken();
        // console.log(token)
        config = {
            ...config,
            headers: { "Authorization": `Bearer ${token}` },
        };
    }
    if (extraConfig == "blob") {
        config = {
            ...config,
            responseType: 'blob',
        }
    }
    else if (extraConfig == "formData") {
        config = {
            ...config,
            headers: {
                ...config.headers,
                "content-type": "multipart/form-data",
            },
        };
    }

    let data;



    await axios({ ...config })
        .then((res) => {
            data = res.data
        })
        .catch((err) => {
            // console.log(err.response.data.message);
            console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;



};