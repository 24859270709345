
import './App.css';
import Routers from "./routes/Routers"
// import Routers from "./Componets/RoutersAdmin"
import Toast from "./Componets/notification";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // console.log(process.env.REACT_APP_API_URL)
  return (
    <div className="App">
      <Routers />
      {/* <RoutersAdmin/> */}
      <Toast />
    </div>
  );
}

export default App;