const initialState = {
    // recognition: false,
    recognition: localStorage.getItem('stateRecog'),
    AcknowledgementId: localStorage.getItem('acknoId'),
    newData: localStorage.getItem('state'),
    MarksSubmitted : localStorage.getItem('stateMarks'),
    coworkingflag : localStorage.getItem('CoworkingFlag'),
    modalGrievence : localStorage.getItem('grievence'),
  };
   
  export const flag = (state = initialState, action) => {
    switch (action.type) {
      case 'FLAG':
        return {
          ...state,
          recognition: action.payload,
        };
      default:
        return state;
    }
  };

  export const coworkingFlag = (state = initialState, action) => {
    switch (action.type) {
      case 'CoworkingFlag':
        return {
          ...state,
          coworkingflag: action.payload,
        };
      default:
        return state;
    }
  };

  export const flag2 = (state = initialState, action) => {
    switch (action.type) {
      case 'FLAG2':
        return {
          ...state,
          newData: action.payload,
        };
      default:
        return state;
    }
  };

  export const grievenceM = (state = initialState, action) => {
    switch (action.type) {
      case 'grievence':
        return {
          ...state,
          modalGrievence: action.payload,
        };
      default:
        return state;
    }
  };

  export const getMarks = (state = initialState, action) => {
    switch (action.type) {
      case 'MARKS':
        return {
          ...state,
          MarksSubmitted: action.payload,
        };
      default:
        return state;
    }
  };

  export const getRegId = (state = initialState, action) => {
    switch (action.type) {
      case 'REGID':
        return {
          ...state,
          AcknowledgementId: action.payload,
        };
      default:
        return state;
    }
  };

  // export default flag;