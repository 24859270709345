import { createStore, combineReducers} from 'redux';
import {flag,flag2,getMarks,getRegId,grievenceM} from './reducers.js';
 
const rootReducer = combineReducers({
  recognition: flag,
  newData : flag2,
  MarksSubmitted:getMarks,
  AcknowledgementId:getRegId,
  modalFlag:grievenceM
});
 
export const store = createStore(rootReducer);