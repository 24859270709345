import { apiFunction } from "../../apiCalls/functions";
export const eventFilter = {
    "sort": {
        "attributes": ["fromDate"],
        "sorts": ["desc"]
    },
    "filters": [
    ]
};
export const galleryFilter = {
    "sort": {
        "attributes": ["uploadedDate"],
        "sorts": ["desc"]
    },
    "filters": [["status", "=", "Active"]]
};
export const trainingFilter = {
    "sort": {
        "attributes": ["startDate"],
        "sorts": ["desc"]
    },
    "filters": [
    ]
};
export const certificateFilter = {
    "filters": [
    ]
};
export const dGReviewFilter = {
    "sort": {
        "attributes": ["uploadedDate"],
        "sorts": ["desc"]
    },
    "filters": [["status", "=", "Active"]]
};
export const mediaFilter = {
    "search": "",
    "sort": {
        "attributes": ["createdDate"],
        "sorts": ["desc"]
    },
    "filters": [
    ],
    "pageNo": 1,
    "itemsPerPage": 100
};
export const notificationFilter = {
    "sort": {
        "attributes": ["fromDate"],
        "sorts": ["desc"]
    },
    "filters": [
    ]
};
//notice section
export const getCertificate = async (data) => {
    return await apiFunction('homepage/list-certificate', "post", data, false, null)
}
export const getNotification = async (data) => {
    return await apiFunction('homepage/list-notification', "post", data, false, null)
}
export const getTraining = async (data) => {
    return await apiFunction('homepage/list-training', "post", data, false, null)
}
export const getGallery = async (data) => {
    return await apiFunction('homepage/list-gallery', "post", data, false, null)
}
export const getDGReview = async (data) => {
    return await apiFunction('homepage/list-dg-review', "post", data, false, null)
}
export const getMedia = async (data) => {
    return await apiFunction('/homepage/list-video', "post", data, false, null)
}
export const getEvent = async (data) => {
    return await apiFunction('homepage/list-event', "post", data, false, null)
}