import React, { useState, useEffect } from 'react'
import Layout from "../layout";

import { BrowserRouter, Route, Routes } from "react-router-dom"
import { getDepartmentToken, getToken } from "../apiCalls/functions"
import StartupIndia from '../pages/StartupIndia/StartupIndia'
import AboutPage from '../pages/about'
import TrainingCalendar from '../pages/trainingCalendar';
import Facilities from '../pages/facilities';
import RTI from '../pages/rti'
import Contact from '../pages/contact'
import SMIC from '../pages/smic'
import PSC from '../pages/psc'
import DGReview from '../pages/dgreview'
import KeyContacts from '../pages/KeyContacts'
import IncubatorCenter from '../pages/IncubatorCenter'
import AtAGlance from '../pages/AtaGlance'
import StartupPrograms from '../pages/startupPrograms'
import DGMessageContainer from '../pages/directorMessage';
import KnowledgePartner from '../pages/knowledgePartner';
import Organisation from '../pages/organisation';
import TrainingRule from '../pages/trainingRules';
import Magazine from '../pages/magazine';
const Routers = () => {
    const [startupToken, setStartupToken] = useState(JSON.parse(localStorage.getItem("bipard-token")))


    useEffect(() => {        
        setStartupToken(startupToken)
    }, []);

    // console.log(startupToken)
    // <Sidebar /> 
    return (

        <div>


            <BrowserRouter>

                <Layout>
                    <Routes>
                        <Route path='/startup-programs-challenges' element={<StartupPrograms />} />
                        <Route path='/' element={<StartupIndia />} />
                        <Route path='/startup-india/:notification' element={<StartupIndia />} />
                        <Route path='/about' element={<AboutPage />} />
                        <Route path='/ataglance' element={<AtAGlance />} />
                        <Route path='/training-calendar' element={<TrainingCalendar />} />
                        <Route path='/director-message' element={<DGMessageContainer />} />
                        <Route path='/knowledge-partner' element={<KnowledgePartner />} />
                        <Route path='/organization' element={<Organisation />} />
                        <Route path='/training-rules' element={<TrainingRule />} />
                        <Route path='/magazine' element={<Magazine />} />
                        <Route path='/facilities' element={<Facilities />} />
                        <Route path='/rti' element={<RTI />} />
                        <Route path='/contacts' element={<Contact />} />
                        <Route path='/key-contacts' element={<KeyContacts />} />
                        <Route path='/smic' element={<SMIC />} />
                        <Route path='/psc' element={<PSC />} />
                        <Route path='/problem-solving-form' element={<AtAGlance />} />
                        <Route path='/incubator-center' element={<IncubatorCenter />} />
                        <Route path='/dg-review' element={<DGReview />} />
                        
                        {/* /dg-review */}
                        {/* <Route path='/hello' element={<Hello />} /> */}
                    </Routes>
                </Layout>
            </BrowserRouter>

        </div>
    )
}

export default Routers