import React, { useEffect } from 'react';

const FacebookPageEmbed = () => {
    // useEffect(() => {
    //     // Load the Facebook SDK script asynchronously
    //     const script = document.createElement('script');
    //     script.async = true;
    //     script.defer = true;
    //     script.crossOrigin = "anonymous";
    //     script.src = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v20.0&appId=2053026848280017";
    //     document.getElementById('fb-root').appendChild(script);

    //     return () => {
    //         // Clean up the script on component unmount
    //         document.getElementById('fb-root').removeChild(script);
    //     };
    // }, []);

    return (
        <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbipard&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2053026848280017"
            width="340"
            height="450"
            style={{ border: 'none', overflow: 'hidden', width: "100% important", borderRadius: "10px", boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)" }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
        // <div>
        //   <div id="fb-root"></div>
        //   <div
        //     className="fb-page"
        //     data-href="https://www.facebook.com/bipard"
        //     data-tabs="timeline"
        //     data-width=""
        //     data-height=""
        //     data-small-header="false"
        //     data-adapt-container-width="true"
        //     data-hide-cover="false"
        //     data-show-facepile="true"
        //     style={{ width: '100%', height: '400px' }}
        //   >
        //     <blockquote cite="https://www.facebook.com/bipard" className="fb-xfbml-parse-ignore">
        //       <a href="https://www.facebook.com/bipard">Bihar Institute of Public Administration and Rural Development</a>
        //     </blockquote>
        //   </div>
        // </div>
    );
};

export default FacebookPageEmbed;
