import React, { useState } from "react";
import kkpathak from '../../assets/img/kkpathak.png'
import './styles.css'
const RTI = (props) => {
  return (
    <>
      <div className="container p-5 page-content">
        <div className="row aligned-center">
          <div className="col-sm-12">
            <div >
              <p>
                <h2 className="text-start fw-bold">DG Message</h2>
                <div className="wrap">
          <div className="imgcard">
            <img src={kkpathak} alt="" />
            <h5><b>K.K. Pathak</b>  I.A.S</h5>
            <h6>Director General, BIPARD</h6>
          </div>
          <p>
            Training is a process by which officials' abilities are increased to
            perform specific tasks. Inspired by the changing global norms as per
            the social demands, training is an opportunity to move forward on
            the path of progress and deal with the challenges faced by society,
            which lacks negativity and is full of positivity. Through training,
            government talents are integrated into the mainstream of the
            nation's development by ensuring the necessary security and rights
            of citizens with transparency. It should be the duty of every public
            servant to try to incorporate the processes of discipline in his
            life and use them responsibly in public life - the inclusion of this
            process in training is necessary.
            <br />
            <br />
            Training of officials and employees does not only mean giving
            information about the requirements and techniques coming into their
            work area. Although all these dimensions are important parts and
            components of training, training is an essential process of
            developing the complete personality of the trainees. It is a
            verified fact that the process of selection of administrative
            officers is long and difficult and the people who are successfully
            selected in this selection process have special talent and knowledge
            of the subject. Therefore, in addition to the education related to
            the work techniques of the officials in the training institute, the
            focus should be on the overall development of their personality. To
            give a real shape to this principle, in addition to regular academic
            training, the trainees in Bipard are given physical education and
            yoga education, sports, etc.
            <br />
            <br />
            Special attention is given. Besides, arrangements are also made for
            India darshan, trekking and mountaineering, adventure travel etc. to
            enhance the knowledge of the officials. Team spirit and leadership
            skills are developed through these external and extracurricular
            activities.
            <br />
            <br />
            The publication of the magazine 'Unnayan', which embodies the
            monthly presentation of this institute, has fully contributed to its
            development. All the officers and other employees involved in this
            are making full use of their will and are witnessing its progress.
            The writings of various scholars on various subjects have made it
            mature.
            <br />
            <br />
            In the last year, BIPARD has created a unique identity for itself
            due to new applications related to training and innovations in
            administrative training. Bihar Government implements training with
            new consciousness through experiments based on new research to meet
            the demand of its various departments as per the requirement. BIPARD
            is showing a unique example of success by delivering quality
            training to many training programs despite short notice.
            <br />
            <br />
            Apart from training on administration and departmental subjects, the
            subject that is most needed in training modules today is discipline.
            Administrative discipline is not only indispensable in the
            achievement of organizational objectives but is also essential in
            personal life. In times of erosion of discipline, transplantation,
            promotion, and establishment of discipline have become the main
            objective and subject of BIPARD training, this training is proving
            to be universal. Without discipline all qualities become useless. To
            prove the proverb 'Multiple dishes without salt, BIPARD starts
            practicing physical exercises and yoga at 5.30 in the morning to
            inculcate discipline among the trainees. The fatigue and stress of
            continuous study throughout the day go away in the evening yoga
            practice. This creates awareness about discipline among the trainees
            and develops a feeling of cooperation, tolerance, participation, and
            adjustment. After accepting the bondage of discipline, they find it
            easier to execute administrative work with commitment and
            dedication. Administrative discipline holds its special place in the
            execution of time-bound tasks like law and order, disaster
            management, elections, etc. because these tasks require a specific
            work system instead of a general work system. This nature and
            diversity of training differentiates BIPARD trainees from other
            trainees. The trainees, after receiving training here, become
            completely disciplined and dedicate themselves to public service in
            the spirit of public welfare, that is why BIPARD focus is especially
            on discipline.
            <br />
            <br />
            Education is the backbone of the country's development. With the
            right direction of education, proper use of technologies becomes
            possible and new technologies are developed. It is well known that
            teachers are the builders of the nation. To implement this, this
            development yagya has been started by training the administrative
            officers and employees as well as teachers through BIPARD. The
            officers, employees and other people involved in raising BIPARD to
            great heights with their multifaceted service deserve
            congratulations. 'Unnayan' is succeeding in its objective by
            communicating this. Best wishes for its presentation.
            <br />
            <br />
          </p>
        </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RTI;
