import React, { useState } from "react";
import './styles.css'
import nalsar from '../../assets/img/Nalsar_University_of_Law.png'
import nisg from '../../assets/img/NISG.jpg'
import gnlu from '../../assets/img/Gujarat_National_Law_University_Logo.png'
import nliu from '../../assets/img/National_Law_Institute_University_Logo Bhopal.png'
import nird from '../../assets/img/1.gif'
import iimbg from '../../assets/img/IIMBG_LOGO_With_Space.png'
import tiss from '../../assets/img/Tata_Institute_of_Social_Sciences_Logo.svg.png'
import iipa from '../../assets/img/iipa.png'
import aol from '../../assets/img/art_of_living.jpg'
import iimkol from '../../assets/img/IIM KOLKATA.jpeg'
const KnowledgePartner = (props) => {
  return (
    <>
      <div className="container p-5 page-content">
        <div className="row aligned-center">
          <div className="col-sm-12">
            <div>

              <p>
              <h2 className="text-start fw-bold">Knowledge Partner</h2>
                  <p>To bring a qualitative change in the standard of training and to give it an impetus, Bipard has entered in MoU with various institutions of national and international repute. Our knowledge partner’s list includes:</p>
                  <table className="table table-bordered table-hover">
                    <tbody><tr>
                      <td width="38">
                        <b>SL</b></td>
                      <td width="109">
                        <b>Logo</b></td>
                      <td width="535">
                        <p align="center">
                          <b>Name of Institution with MoU</b></p></td>
                      <td width="200">
                        <b>Place</b></td>
                    </tr>
                      <tr>
                        <td align="right">
                          1.</td>
                        <td className="text-center">
                          <img border="0" src={nalsar} className="w-75" /></td>
                        <td >
                          THE NATIONAL ACADEMY OF LEGAL STUDIES AND RESEARCH (NALSAR UNIVERSITY OF
                          LAW)</td>
                        <td align="center" class="Uline" >
                          HYDERABAD</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          2.</td>
                        <td className="text-center"><img border="0" src={nisg} className="w-75" /></td>
                        <td >NATIONAL
                          INSTITUTE OF SMART GOVERNMENT (NISG)</td>
                        <td align="center" class="Uline" >
                          HYDERABAD</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          3.</td>
                        <td className="text-center"><img border="0" src={gnlu} className="w-75" /></td>
                        <td >
                          GUJRAT NATIONAL LAW UNIVERSITY</td>
                        <td align="center" class="Uline" >
                          GANDHI NAGAR</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          4.</td>
                        <td className="text-center"><img border="0" src={nliu} className="w-75" /></td>
                        <td >THE
                          NATIONAL LAW INSTITUTE UNIVERSITY</td>
                        <td align="center" class="Uline" >
                          BHOPAL</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          5.</td>
                        <td className="text-center"><img border="0" src={nird} className="w-75" /></td>
                        <td >
                          NATIONAL INSTITUTE OF RURAL DEVELOPMENT (NIRD)</td>
                        <td align="center" class="Uline" >
                          HYDERABAD</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          6.</td>
                        <td className="text-center"><img border="0" src={iimbg} className="w-75" /></td>
                        <td >
                          INDIAN INSTITUTE OF MANAGEMENT (IIM)</td>
                        <td align="center" class="Uline" >
                          BODHGAYA</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          7.</td>
                        <td className="text-center"><img border="0" src={tiss} className="w-75" /></td>
                        <td >TATA
                          INSTITUTE OF SOCIAL SCIENCES (TISS)</td>
                        <td align="center" class="Uline" >
                          MUMBAI</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          8. </td>
                        <td className="text-center"><img border="0" src={iipa} className="w-75" /></td>
                        <td >
                          INDIAN INSTITUTE OF PUBLIC ADMINISTRATION (IIPA)</td>
                        <td align="center" class="Uline" >
                          NEW DELHI</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          9.</td>
                        <td className="text-center"><img border="0" src={aol} className="w-75" /></td>
                        <td >THE
                          ART OF LIVING</td>
                        <td align="center" class="Uline" >
                          BANGALORE</td>
                      </tr>
                      <tr>
                        <td align="right" >
                          10</td>
                        <td className="text-center"><img border="0" src={iimkol} className="w-75" /></td>
                        <td >
                          INDIAN INSTITUTE OF MANAGEMENT (IIM)</td>
                        <td align="center" class="Uline" >
                          CALCUTTA</td>
                      </tr>
                    </tbody>
                  </table>
                </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnowledgePartner;
