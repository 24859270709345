import React, { useState } from "react";
import './style.css'
const BannerCarousel = (props) => {
    return (
        <>
            <div id="carouselExampleCaptions" className="w-100 carousel slide mt-4" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {
                        props?.carouselData?.map((e, i) => (
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={i} className={i == 0 ? "active" : ""} aria-current="true" aria-label={"Slide" + i}></button>
                        ))
                    }
                </div>
                <div className="carousel-inner">
                    {
                        props?.carouselData?.map((e, i) => (
                            <div className={`carousel-item ${i == 0 ? "active" : ""}`}>
                                <img src={e.img} style={{ width: props?.type == 'flyer' ? "40%" : "100%" }} />
                            </div>
                        ))
                    }
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    );
};

export default BannerCarousel;