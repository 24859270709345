import React, { useState } from "react";
import './styles.css'
const RTI = (props) => {
  return (
    <>
      <div className="container p-5 page-content">
        <div className="row aligned-center">
          <div className="col-sm-12">
            <div className="description-card" style={{ overflow: "hidden" }}>
              <p>
                <h2 className="text-start fw-bold">RTI</h2>
                <p>The Right to Information Bill was introduced in the Lok Sabha in December 2004. It was passed by both houses of Parliament in May 2005. The assent of the President was received on June 15 and the Act was notified in the Gazette on June 21. The law has become operational on 12th October, 2005.</p>
                <p>This law was passed by Parliament to enable citizens to exercise their fundamental right to information held by the public authorities all over the country (except Jammu and Kashmir). The RTI Act aims to bring about transparency in the functioning of public authorities, contain corruption and hold Governments and their instrumentalities accountable to people. It creates a process for providing information to people. The RTI Act places a duty on officers to provide information to people both proactively and upon request. It provides for a two-tier appeal mechanism to deal with complaints of unreasonable denial of information by public authorities. This law will have an overriding effect vis-a-vis the Official Secrets Act, 1923 and all other laws and orders that restrict information flow to people.</p>
                <ul>
                  <li><a target="_blank" href="rti/RTI-ProactiveDisclosure-BIPARD.pdf">Voluntary disclosures under RTI Act, 2005</a></li>
                  <li><a target="_blank" href="rti/Bihar%20RTI%20Rules_eng.pdf">The Bihar Right to Information Rules, 2005</a></li>
                  <li><a target="_blank" href="rti/Right%20to%20Information%20Act%20book.pdf">Guide Book on Right to Information</a></li>
                  <li><a target="_blank" href="rti/rtiyearly11.pdf">RTI for Year 2010-11</a></li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RTI;
