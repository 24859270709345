import React, { useState } from "react";
import './styles.css'
const Contact = (props) => {
  return (
    <>

      <div className="container p-5 page-content">
        <h2 className="text-start fw-bold">Contact</h2>
        <div className="row aligned-center img-box-rec">
          <div className="col-sm-6">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Patna Address</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Gaya Address</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <hr />
                <p>
                  <b>Bihar Institute of Public Administration & Rural Development</b><br />
                  Walmi Campus,<br />
                  Phulwarisharif,<br />
                  Patna 801 505.<br /><br />
                  <b>Telephone: 91-612-2452585</b><br />
                  <b>Telefax: 91-612-2452586</b><br /><br />
                  <b>For any complaint and suggestion, send email to<br /><br /></b>

                  <b>E-mail:</b><br />
                  1. <a href="mailto:ad-bipard-admin@bihar.gov.in">ad-bipard-admin@bihar.gov.in</a><br />
                  2. <a href="mailto:dgbipard@yahoo.in">dgbipard@yahoo.in</a><br />
                  3. <a href="mailto:bipardpatna@yahoo.in">bipardpatna@yahoo.in</a>


                </p>
              </div>
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <hr />
                <p>
                  <b>Bihar Institute of Public Administration & Rural Development</b><br />
                  Kushdihra,<br />
                  Paharpur, Bihar,<br />
                  Gaya, 823001.<br /><br />
                  <b>Telephone: +91-631-2222408</b><br />
                  <b>For any complaint and suggestion, send email to<br /><br /></b>

                  <b>E-mail:</b><br />
                  1. <a href="mailto:bipard.gaya-bih@nic.in">bipard.gaya-bih@nic.in</a><br />
                  2. <a href="mailto:bipardgaya@yahoo.com">bipardgaya@yahoo.com</a><br />


                </p>
              </div>
            </div>

          </div>
          <div className="col-sm-6">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab-1" data-bs-toggle="pill" data-bs-target="#pills-home-1" role="tab" aria-controls="pills-home-1" aria-selected="true">Patna Location</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab-1" data-bs-toggle="pill" data-bs-target="#pills-profile-1" role="tab" aria-controls="pills-profile-1" aria-selected="false">Gaya Location</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-home-1" role="tabpanel" aria-labelledby="pills-home-tab-1">
                <hr />
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3599.0293050003247!2d85.04548551501703!3d25.57069178372382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed56275a98a277%3A0xe534cdccc4d1f734!2sBihar%20Institute%20of%20Public%20Administration%20%26%20Rural%20Development%2C%20WALMI%20Campus!5e0!3m2!1sen!2sin!4v1687428105360!5m2!1sen!2sin" style={{ width: "100%", height: "450px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <div class="tab-pane fade" id="pills-profile-1" role="tabpanel" aria-labelledby="pills-profile-tab-1">
                <hr />
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.9971137362054!2d84.9778711!3d24.761288099999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f32b0621fc9a87%3A0xef0bbbf59d09192f!2sBIPARD%20Gaya!5e0!3m2!1sen!2sin!4v1710900799057!5m2!1sen!2sin" style={{ width: "100%", height: "450px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Contact;
