import React, { useCallback, useEffect, useMemo, useState } from "react";
import './styles.css'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getTraining, trainingFilter } from "../../Componets/basicAction";
const TrainingCalendar = (props) => {
  const [eventModal, setEventModal] = useState({ "status": false, "data": {} });
  const [trainingData, setTrainingData] = useState([]);
  const onGetTraining = async () => {
    let data = await getTraining(trainingFilter);
    if (data.status) {
      setTrainingData(data.data.data);
    }
  };
  useEffect(() => {
    onGetTraining();
  }, []);
  const formattedEventData = trainingData.map((event) => ({
    id: event.id,
    title: event.name,
    start: new Date(event.startDate),
    end: new Date(event.endDate),
  }));
  console.log(formattedEventData);
  console.log(trainingData);
  const localizer = momentLocalizer(moment) // or globalizeLocalizer
  const { views, ...otherProps } = useMemo(() => ({
    views: {
      month: true
    }
  }), [])
  let events = [
    {
      "id": 1,
      "title": "Training programme of LDC District (In-Service)",
      "start": "2023-05-25T00:00:00.000Z",
      "end": "2023-06-21T00:00:00.000Z"
    },
    {
      "id": 2,
      "title": "Training programme of Bihar Financial Services",
      "start": "2023-06-05T00:00:00.000Z",
      "end": "2023-09-03T00:00:00.000Z"
    }
  ];
  const [myEvents, setEvents] = useState(events)
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )
  const handleSelectEvent = useCallback(
    (event) => {
      setEventModal({ "status": true, "data": event })
      //console.log(eventModal);
    },
    [events]
  )
  useEffect(() => {
    const rbcOverlay = document.querySelector('.rbc-overlay');
    console.log(rbcOverlay);
    if (rbcOverlay) {
      rbcOverlay.style.minWidth = '152.813px !important';
      rbcOverlay.style.top = '65% !important';
      rbcOverlay.style.left = '50% !important';
      rbcOverlay.style.transform = 'translate(-50%, -50%) !important';
      rbcOverlay.style.maxHeight = '500px !important';
      rbcOverlay.style.overflowY = 'scroll !important';
      rbcOverlay.style.zIndex = '99999 !important';
    }
  }, []);
  return (
    <>

      <div className="container-fluid page-content">
        <div className="row aligned-center">
          <div className="col-sm-10 p-5">
            <h3 classNae="text-start fw-bold">Training Calendar</h3>
            <div className="notice-box" style={{ position: "relative" }}>
              {
                eventModal.status ?
                  <div className="event-body" onClick={(e) => { setEventModal(false) }}>
                    <div className="event-child" >
                      <div className="close-icon"><i onClick={(e) => { setEventModal(false) }} className="fa fa-close"></i></div>
                      <h5 className="mt-2 mb-0">{eventModal?.data?.title}</h5>
                      <hr />
                      <p className="mt-3">Event Date<br /><br /><b>{moment(eventModal?.data?.start).format("dddd, MMMM DD, yyyy")}<br />{eventModal?.data?.end != eventModal?.data?.start ? <>-<br />{moment(eventModal?.data?.end).format("dddd, MMMM DD, yyyy")}</> : ""}</b></p>
                      <p>{eventModal?.data?.description}</p>
                    </div>
                  </div> : ""
              }
              <Calendar
                id="calendar-1"
                style={{ fontSize: "12px", height: 400, borderRadius: "10px" }}
                localizer={localizer}
                events={formattedEventData}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                views={views}
                defaultDate={formattedEventData?.[0]?.start}
                popup
              />
              <style>{`
        .rbc-overlay {
          min-width: 152.813px !important;
          top: 65% !important;
          left: 50% !important;
          transform: translate(-50%, -50%) !important;
          max-height: 500px !important;
          overflow-y: scroll !important;
          z-index: 99999 !important;
        }
      `}</style>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingCalendar;
