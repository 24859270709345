import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './styles.css'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const Facilities = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Update the URL hash without reloading the page
    window.history.replaceState(null, null, `#${newValue}`);
  };
  const handleHashChange = () => {
    const hash = window.location.hash;
    if (hash) {
      const tabIndex = parseInt(hash.replace("#", ""), 10);
      if (!isNaN(tabIndex)) {
        setValue(tabIndex);
      }
    }
  };

  useEffect(() => {
    handleHashChange(); // Check the hash when the component mounts
    window.addEventListener("hashchange", handleHashChange); // Listen for hash changes

    return () => {
      window.removeEventListener("hashchange", handleHashChange); // Cleanup on unmount
    };
  }, []);
  return (
    <>

      <div className="container p-5 page-content">
        <h2 className="text-start fw-bold">Facilities</h2>
        <div className="row mt-3">
          <div className="col-sm-2 p-0 m-0 border-end">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
            >
              <Tab label="Computer Lab" className="w-100" {...a11yProps(0)} />
              
              <Tab label="Dinning Hall" className="w-100" {...a11yProps(1)} />
              <Tab label="Hostel" className="w-100" {...a11yProps(2)} />
              <Tab label="Smart Classroom" className="w-100" {...a11yProps(3)} />
              {/* <Tab label="Training Center" className="w-100" {...a11yProps(4)} /> */}
              <Tab label="Health Center" className="w-100" {...a11yProps(4)} />
              <Tab label="Sports/Gym" className="w-100" {...a11yProps(5)} />
              <Tab label="Yoga/PT" className="w-100" {...a11yProps(6)} />
              <Tab label="Library" className="w-100" {...a11yProps(7)} />
            </Tabs>
          </div>
          <div className="col-sm-10">
            
            <TabPanel value={value} index={0}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>About</h1></div>
                  <p>
            The Bihar Institute of Public Administration and Rural Development
            is one of the most important regulatory departments of the State
            Government. Subsequent to the formation of Jharkhand State, the
            Administrative Training Institute (ATI) and the State Institute of
            Rural Development (SIRD) by virtue of their location at Ranchi
            vested with the State of Jharkhand. <br /> <br />
            The Officers and the staff of the State of Bihar need to be trained
            in public administration, good governance etc and for that the
            Government through its notification no. 68 dated 28th March 2002
            established the Administrative Training Institute (ATI) at Water and
            Land Management Institute (WALMI), Phulwarisharif, Patna. <br />{" "}
            <br />
            The State Institute of Rural Development (SIRD) was similarly
            created by the Govt. of Bihar through notification no. 7036 dated
            15/09/2005 and was located at WALMI, Phulwarisharif, Patna along
            with the ATI. The Director General of ATI was designated the
            Director General of SIRD as well.
          </p>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>About</h1></div>
                  <p>
            BIPARD prides itself on offering exceptional dining experiences at
            our Officers Mess, where comfort and enjoyment are paramount. The
            Officer's Mess bring together a variety of cuisines, cultures,
            traditions, practices and beliefs. This fosters and nurtures the
            spirit of universal brotherhood and fraternity amongst the trainees.
            The Mess is mandated to achieve the highest standards in terms of
            decorum, conduct and services.
            <h4>Niranjana Mess:</h4>
            <ul>
              <li>
                Two Dining Halls: Providing generous space for dining and social
                interactions.
              </li>
              <li>
                Kitchen Area: Equipped with modern amenities for efficient meal
                preparation.
              </li>
              <li>
                Dining Hall No. 1: Spacious environment with seating for 230,
                perfect for gatherings.
              </li>
              <li>
                Executive Dining Hall: Exclusive area accommodating up to 44
                guests in an elegant setting.
              </li>
            </ul>
            <h4>Annapurna Mess:</h4>
            <ul>
              <li>
                One Welcoming Dining Hall: Creating a friendly atmosphere for
                dining and camaraderie.
              </li>
              <li>
                Well-equipped Kitchen Area: Catering to the culinary needs of
                our esteemed guests.
              </li>
              <li>
                Capacity: Seating for 180, ensuring ample space for delightful
                dining experiences.
              </li>
            </ul>
            <h4>Sujata Mess:</h4>
            <ul>
              <li>
                Inviting Dining Hall: Providing a cozy ambiance for dining and
                relaxation.
              </li>
              <li>Capacity: Comfortably accommodating up to 180 guests.</li>
            </ul>
            At BIPARD, our commitment is to deliver more than just meals; we
            strive to create memorable dining experiences that foster
            camaraderie and community among our esteemed guests. Our Officers
            Mess facilities are meticulously maintained, upholding the highest
            standards of hygiene, comfort, and satisfaction for all patrons.
          </p>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>About</h1></div>
                  <p>
            <b>
              Experience Comfort and Convenience at BIPARD's Residential
              Facilities
            </b>
            <br />
            Welcome to Bihar Institute of Public Administration & Rural
            Development (BIPARD), where we prioritize your comfort and
            well-being with our meticulously crafted residential accommodations.
            Our Executive Hostels blend modern amenities with thoughtful design,
            ensuring a delightful stay experience for all our officers and
            guests.
            <h4>Executive Hostel Amenities:</h4>
            <ul>
              <li>
                <b>Fully Furnished Rooms:</b> Immerse yourself in the comfort of
                our fully furnished accommodations, meticulously designed for
                relaxation and convenience.
              </li>
              <li>
                <b>24/7 Surveillance:</b> Your safety is our utmost priority.
                Our advanced surveillance systems and vigilant personnel ensure
                round-the-clock security, providing you with peace of mind
                throughout your stay.
              </li>
              <li>
                <b>Inviting Common Areas:</b> Foster connections and unwind in
                our spacious common areas, thoughtfully designed for relaxation
                and social interactions. Whether you're catching up with
                colleagues or enjoying some quiet downtime, our common areas
                cater to your every need.
              </li>
              <li>
                <b>Impeccable Housekeeping:</b> Our dedicated housekeeping team
                works tirelessly round the clock to maintain cleanliness and
                orderliness, ensuring a pristine living environment for all our
                residents.
              </li>
            </ul>
            <h4>Discover Our Hostels:</h4>
            <ul>
              <li>
                <b>Brahmaputra Executive Hostel:</b> Experience the grandeur of
                our largest and oldest establishment, boasting 295 rooms, lush
                lawns, and constant surveillance for your security and comfort.
              </li>
              <li>
                <b>Ganga Executive Hostel:</b> Find serenity in our tranquil
                establishment with 84 rooms, serene lawns, and round-the clock
                surveillance, providing you with a peaceful and secure
                environment.
              </li>
              <li>
                <b>Nilgiri Executive Hostel:</b> Enjoy the comfort of 40
                well-appointed rooms, complemented by convenient common area
                amenities and surveillance coverage, ensuring your stay is both
                comfortable and secure.
              </li>
              <li>
                <b>Aravali Executive Hostel:</b> Indulge in the elegance of our
                32 furnished rooms, accompanied by round-the-clock surveillance
                and common area facilities, providing you with a perfect blend
                of comfort and security.
              </li>
              <li>
                <b>Amarkantak Executive Hostel:</b> Luxuriate in our spacious
                accommodations with 70 rooms and common area amenities, ensuring
                your stay is both comfortable and enjoyable.
              </li>
              <li>
                <b>Vipulgiri Hostel:</b> Experience coziness and tranquility in
                our 16 rooms, complemented by common area amenities and vigilant
                surveillance, providing you with peace of mind during your stay.
              </li>
              <li>
                <b></b>Ratnagiri Hostel: Discover comfort and security in our 15
                rooms, along with common area facilities, ensuring your stay is
                both pleasant and secure.
              </li>
              <li>
                <b>Udaygiri Hostel:</b> Find solace and relaxation in our 16
                well-appointed rooms, accompanied by common area amenities and
                surveillance coverage, ensuring your stay is both comfortable
                and secure.
              </li>
              <li>
                <b>Songiri Hostel:</b> Experience comfort and convenience in our
                16 rooms, complemented by common area facilities and 24/7
                surveillance, ensuring your stay exceeds expectations.
              </li>
              <li>
                <b>Vaibhavgiri Hostel:</b> Indulge in comfort and convenience
                across our 16 meticulously designed rooms, accompanied by common
                area amenities and round-the-clock surveillance, guaranteeing a
                stay that surpasses your expectations.
              </li>
            </ul>
            <h4>Additional Residential Buildings:</h4>
            <ul>
              <li>
                <b>Kanchanjanga Faculty Resident:</b> Designed exclusively for 
                Assistant Directors and faculty members, offering exceptional 
                amenities for a rewarding stay experience.
              </li>
              <li>
                <b>Kaveri Executive Guest House:</b> Reserved for esteemed
                guests and visiting personnel, providing unparalleled facilities
                within the campus premises for a memorable and comfortable stay.
              </li>
            </ul>
            At BIPARD, we are committed to providing you with a delightful
            residential experience, where comfort, security, and convenience are
            paramount.
          </p>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>About</h1></div>
                  <p>The Bihar Institute of Public Administration and Rural Development is one of the most important regulatory departments of the State Government. Subsequent to the formation of Jharkhand State, the Administrative Training Institute (ATI) and the State Institute of Rural Development (SIRD) by virtue of their location at Ranchi vested with the State of Jharkhand. <br /> <br />
              The Officers and the staff of the State of Bihar need to be trained in public administration, good governance etc and for that the Government through its notification no. 68 dated 28th March 2002 established the Administrative Training Institute (ATI) at Water and Land Management Institute (WALMI), Phulwarisharif, Patna. <br /> <br />
              The State Institute of Rural Development (SIRD) was similarly created by the Govt. of Bihar through notification no. 7036 dated 15/09/2005 and was located at WALMI, Phulwarisharif, Patna along with the ATI. The Director General of ATI was designated the Director General of SIRD as well.</p>
          
                </p>
                <div className="heading"><h1>Classes Timing</h1></div>
                <p>
                      <h6>08:30AM-10:00AM</h6>
                      <h6>10:00AM-11:30AM</h6>
                      <h6>11:45AM-01:15PM</h6>
                      <h6>02:15PM-03:45PM</h6>
                      <h6>03:45PM-05:15PM</h6>
                      <h6>05:15PM-06:15PM</h6>
                    </p>
                    <p>Days-(Monday - Saturday)</p>
              </div>
            </TabPanel>
            {/* <TabPanel value={value} index={4}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>BIPARD has following centres:</h1></div>
                  <p>
                    <div className="accordion mt-3" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                            1. Centre for e-Governance (CEG)
                          </button>
                        </h2>
                        <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <table class="contentpaneopen">
                              <tbody>
                                <tr>

                                  <td class="createdate" height="19"><strong><font color="#000000" size="2">About
                                    the Centre</font></strong></td>
                                </tr>
                                <tr>

                                  <td><font size="2">In view of diverse operations of government
                                    high speed transactions is a must and government can not afford to discomfort
                                    people. E-Governance is the only answer.</font></td>
                                </tr>

                                <tr>
                                  <td>&nbsp;</td>
                                </tr>

                                <tr>

                                  <td class="createdate" height="13"><strong><font color="#000000" size="2">Vision</font></strong></td>
                                </tr>

                                <tr>

                                  <td><font size="2">The Centre for e-Governance was established
                                    in financial year 2005-06 with dual purpose. </font>
                                    <blockquote>
                                      <ul>
                                        <li><font size="2">To provide functional training to officers/Employees
                                          of the Government of Bihar in area related to computers/Computer Awareness,
                                          Data Base Management System (DBMS) and Management Information System
                                          (MIS). </font></li>
                                        <li><font size="2">In near future we are in step to develop application
                                          software for use in government/Nodal departments and offices/or other
                                          offices concerns. </font></li>
                                      </ul>
                                    </blockquote>
                                  </td>
                                </tr>

                                <tr>
                                  <td class="createdate">&nbsp;</td>
                                </tr>
                                <tr>

                                  <td class="createdate" height="17"><font color="#000000" size="2"><strong>Mission</strong></font></td>
                                </tr>
                                <tr>

                                  <td><font size="2">Centre for e-Governance is primarily responsibilities
                                    for conducting training programme information and communications technology
                                    but it also has an important role of providing internal information technology
                                    support.</font></td>
                                </tr>

                                <tr>
                                  <td class="createdate">&nbsp;</td>
                                </tr>
                                <tr>

                                  <td class="createdate" height="17"><font color="#000000" size="2"><strong>Objective</strong></font></td>
                                </tr>
                                <tr>

                                  <td><b><font color="#000000" size="3">e-Governance : Short Term Priorities</font></b>
                                    <blockquote>
                                      <ul>

                                        <li><font size="2">Analyzing and planning various IT courses. </font></li>
                                        <li><font size="2">Upgrading BIPARD network infrastructure. </font></li>
                                        <li><font size="2">Installing dedicated Internet facility. </font></li>
                                        <li><font size="2">Installing e-mail server for effective communications.</font></li>
                                        <li><font size="2">Developing various application for BIPARD administration
                                          and various centres.</font></li>
                                      </ul>
                                    </blockquote>

                                    <p><b><font color="#000000" size="3">e-Governance : Long Term Priorities</font></b></p>
                                    <blockquote>
                                      <ul>

                                        <li><font size="2">Setting up state-of-the-art e-Governance facilitation
                                          Centre in collaboration with other Information Technology (State/other
                                          sectors) concerns.</font></li>
                                        <li><font size="2">Setting up BIPARD e-Learning centre and developing
                                          training courses for distant learning. </font></li>
                                        <li><font size="2">Developing software's in collaboration with NIC/other
                                          Information Technology concern inside state, for various government
                                          departments to standardize and eliminate high cost of development.
                                          Centre for e-Governance is primarily responsibilities for conducting
                                          training programme information and communications technology but it
                                          also has an important role of providing internal information technology
                                          support.</font></li>
                                      </ul>
                                    </blockquote>
                                  </td>
                                </tr>

                                <tr>
                                  <td>&nbsp;</td>
                                </tr>

                                <tr>

                                  <td class="createdate" height="22"><font color="#000000" size="2"><strong>Funding
                                    Model</strong></font></td>
                                </tr>

                                <tr>

                                  <td><font size="2">The Department of Finance/Development/Science
                                    &amp; Technology Govt. of India/ Govt. of Bihar, UNDP may support the BIPARD
                                    centre's finances. </font></td>
                                </tr>

                                <tr>
                                  <td class="createdate">&nbsp;</td>
                                </tr>
                                <tr>

                                  <td class="createdate" height="17"><font color="#000000" size="2"><strong>Activities</strong></font></td>
                                </tr>
                                <tr>

                                  <td><b><font color="#000000" size="3">SCORE software implementation in all registration
                                    offices of Government of Bihar</font></b>
                                    <blockquote>

                                      <p><font size="2">In BIPARD, we have conducted SCORE software training
                                        (14 Batches) to all 38 districts and Sub divisional Registry offices
                                        (368 personnel). At present all sale deed/other registration deeds become
                                        operational online computerized deeds in Registration Department, Government
                                        of Bihar.The Department of Finance/Development/Science &amp; Technology
                                        Govt. of India/ Govt. of Bihar, UNDP may support the BIPARD centre's
                                        finances.</font> </p>
                                    </blockquote>

                                    <p><b><font color="#000000" size="3">VAT (Value Added Tax) "VICTORY" software
                                      training for Commercial Tax Department, Government of Bihar </font></b>
                                    </p>
                                    <blockquote>

                                      <p><font size="2">At present BIPARD, under e-Governance project, we are
                                        conducting "VICTORY" software training for officers/employees
                                        of Commercial Tax Department, Govt. of Bihar.</font></p>
                                    </blockquote>
                                  </td>
                                </tr>

                                <tr>
                                  <td class="createdate">&nbsp;</td>
                                </tr>
                                <tr>

                                  <td class="createdate" height="17"><font color="#000000" size="2"><strong>Major
                                    Initiatives</strong></font></td>
                                </tr>
                                <tr>

                                  <td><b><font color="#000000" size="3">e-Governance facilitation Centre</font></b>
                                    <ul>

                                      <li><font size="2">The purpose of the Centre would be to create the desired
                                        capacity within each department to work out their computerization and
                                        e-Governance projects, their speedy development and effective implementation
                                        up to the field level. </font></li>
                                      <li><font size="2">The facilities which to be provided at the centre,
                                        would include: </font>
                                        <ul>
                                          <li><font size="2">Administrative Block </font></li>
                                          <li><font size="2">IT Incubation Centre o Computer Labs for testing
                                            hardware and software.</font></li>
                                          <li><font size="2">Demo Labs for showcasing various e-Governance projects.</font></li>
                                          <li><font size="2">Auditorium </font></li>
                                          <li><font size="2">IT Training centre</font></li>
                                        </ul>
                                        <p><font size="2">This infrastructure should be created by joint venture
                                          partners for imparting training to government officials who are involved
                                          in computerization process in their respective departments. This would
                                          also be setup to give boost to software expert by providing software
                                          incubation centre to pioneering software entrepreneurs.  The
                                          centre would also provide year long services through the trained government
                                          officials within the respective organisations to maintain their Information
                                          Technology infrastructure. </font></p>
                                        <p><font size="2">It would be clear that what is intended here is to
                                          provide 'one stop e-shop' for various e-Governance and computerization
                                          projects of state government. This centre would co-ordinate all the
                                          activities of these projects with the help of NIC, private partners
                                          and BIPARD.</font></p>

                                        <p><b><font size="2">BIPARD's participation in setting up an e-network
                                          to link major institutes in India/State of Bihar</font></b></p>

                                        <p><font size="2">The purpose of this e-network is sharing of resources
                                          among the training institutes and strengthen the capacity of all training
                                          institutes. The would be based on Knowledge Management &amp; creating
                                          knowledge base for sharing knowledge and resources among various institutes.
                                          Once in place this e-network would also enable distance-learning programs,
                                          collaborative activities among institutes and a system for sharing
                                          resources/programs.</font></p></li>
                                    </ul>
                                  </td>
                                </tr>

                                <tr>
                                  <td>&nbsp;</td>
                                </tr>

                                <tr>

                                  <td class="createdate"><font size="2"><strong>Co-ordinator</strong></font></td>
                                </tr>
                                <tr>

                                  <td> <strong><font size="3">Mr. Om Prakash Agrawal </font></strong></td>
                                </tr>


                              </tbody></table>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            2. Centre for Disaster Management (CDM)
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">

                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            3. Centre for District Training (CDT)
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">

                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            4. Centre for Local Self Governance (LSG)
                          </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                          <div className="accordion-body">

                          </div>
                        </div>
                      </div>
                    </div>
                  </p>

                </p>
              </div>
            </TabPanel> */}
            <TabPanel value={value} index={4}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>About</h1></div>
                  <p>
            BIPARD is dedicated to ensuring the health and well-being of our
            community through our comprehensive Primary Health Centre. Our
            facility offers a wide range of medical services to address various
            health concerns, providing timely diagnosis, treatment, and care.
            <h4>Medical Services:</h4>
            <ul>
              <li>
                Outpatient Department (OPD): Our OPD provides consultations and
                medical care for individuals seeking treatment for a variety of
                health issues.
              </li>
              <li>
                Diagnosis and Treatment: We offer thorough diagnostic
                assessments and personalized treatment plans to address the
                needs of each patient.
              </li>
              <li>
                General Operating Theatre (OT): Equipped to perform minor
                surgical procedures and medical interventions as required.
              </li>
              <li>
                Body Mass Index (BMI) Monitoring: We monitor BMI to assess and
                manage weight-related health concerns effectively.
              </li>
              <li>
                Electrocardiogram (ECG): Our facility conducts ECG tests to
                evaluate cardiac health and detect any abnormalities.
              </li>
              <li>
                Blood Pressure Monitoring: We offer regular monitoring of blood
                pressure levels to ensure optimal health management.
              </li>
            </ul>
            <h4>Impatient Facilities:</h4>
            <ul>
              <li>
                14-Bed Ward: Our comfortable inpatient ward features air
                conditioning and room heaters, providing a conducive environment
                for recovery.
              </li>
              <li>
                24/7 Ambulance Service: We offer round-the-clock ambulance
                services with trained staff for emergency medical situations.
              </li>
              <li>
                Continuous Oxygen Supply: Oxygen is readily available 24/7 to
                support patients requiring oxygen therapy.
              </li>
              <li>
                Nebulization Facilities: Our facility provides nebulization
                therapy for respiratory conditions.
              </li>
            </ul>
            <h4>Support Services:</h4>
            <ul>
              <li>
                24/7 Nursing Staff: Male and female nursing staff are available
                around the clock to provide attentive care to patients.
              </li>
              <li>
                Male and Female Doctors: Experienced medical professionals are
                on duty 24/7 to provide consultations and medical care.
              </li>
              <li>
                Blood and Urine Tests: We conduct diagnostic tests to aid in
                accurate assessment and diagnosis of medical conditions.
              </li>
              <li>
                Emergency Tie-Ups: In case of emergencies, we have established
                tie-ups with higher centers for seamless referral and
                management.
              </li>
              <li>
                Promoting Good Health: We are committed to promoting good health
                through education and wellness initiatives within our community.
              </li>
              <li>
                24/7 Pharmacy: Our pharmacy remains open round the clock,
                ensuring access to medications and healthcare products whenever
                needed.
              </li>
            </ul>
            We maintain a comprehensive stock of medications to facilitate
            prompt treatment. At BIPARD, we prioritize the health and well-being
            of our community, providing accessible and comprehensive healthcare
            services through our Primary Health Centre.
            <h4>Primary Health Centre:</h4>
            BIPARD's Primary Health Centre ensures comprehensive healthcare,
            offering consultations, diagnosis, and treatment through our
            outpatient department. Equipped with a general operating theatre, we
            conduct minor surgical procedures and interventions. Our inpatient
            facilities feature a 14-bed ward with continuous oxygen supply and
            nebulization facilities for respiratory conditions. With 24/7
            ambulance service and emergency tie-ups, we ensure swift response in
            critical situations. Experienced male and female doctors, along with
            nursing staff, provide round-the-clock care. Additionally, our
            pharmacy remains open 24/7, underscoring our commitment to community
            health and well-being.
          </p>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>About</h1></div>
                  <p>
            BIPARD is committed to fostering a culture of health and fitness
            among its community members. Our state-of-the-art sports facilities
            offer a diverse range of activities to cater to various interests
            and fitness levels.
            <h4>Indoor Sports Complex:</h4>
            Within our Major Dhyan Chand Indoor Sports Complex, offering a
            diverse range of activities:-
            <ul>
              <li>
                <b>Badminton courts:</b> BIPARD's indoor badminton court offers a
                professional setting for players of all levels. Impeccably
                maintained flooring and vibrant court markings ensure optimal
                gameplay. Ample seating and state-of-the-art lighting enhance
                the viewing experience. Dedicated equipment zones provide
                everything needed for an immersive and uninterrupted game.
              </li>
              <li>
                <b>Table tennis:</b> BIPARD's indoor table tennis facility provides an
                ideal environment for enthusiasts of the sport. With well
                maintained tables and professional-grade equipment, players can
                enjoy high-quality gameplay. The venue features ample space for
                matches and practice sessions, ensuring a fulfilling experience
                for players of all skill levels. State-of-the-art lighting
                enhances visibility, allowing for precision and accuracy in
                every shot.
              </li>
            </ul>
            <h4>Outdoor Sports Complex:</h4>
            Our expansive outdoor sports complex boasts an array of facilities:
            <ul>
              <li>
                A swimming pool complex for aquatic fitness and relaxation.
              </li>
              <li>A football ground for team sports and matches.</li>
              <li>
                A sprinter track, eco-flex, and joggers track for running
                enthusiasts.
              </li>
              <li>
                Volleyball and basketball courts for group play and tournaments.
              </li>
              <li>Lawn tennis courts for outdoor racquet sports.</li>
              <li>An asphalt cycling track for cycling enthusiasts.</li>
              <li>
                A landscaped park providing serene spaces for outdoor
                recreation.
              </li>
            </ul>
            <h4>Additional Amenities:</h4>
            In addition to the aforementioned facilities, BIPARD offers:
            <ul>
              <li>
                A fully equipped gymnasium for strength training and
                cardiovascular workouts.
              </li>
              <li>
                Various physical activities including Physical Training (PT) and
                Yoga sessions to promote overall well-being.
              </li>
              <li>An open-air theatre for cultural events and performances.</li>
              <li>
                Various lounges in hostels equipped with board games and
                recreational activities for leisure time enjoyment.
              </li>
            </ul>
            At BIPARD, we believe in the importance of staying active and
            healthy. Our comprehensive sports facilities cater to individuals of
            all ages and fitness levels, promoting physical fitness, mental
            well-being, and a sense of community among our members.
          </p>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>About</h1></div>
                  <p>
            BIPARD is committed to fostering a culture of health and fitness
            among its community members. Our state-of-the-art sports facilities
            offer a diverse range of activities to cater to various interests
            and fitness levels.
            <h4>Indoor Sports Complex:</h4>
            Within our Major Dhyan Chand Indoor Sports Complex, offering a
            diverse range of activities:-
            <ul>
              <li>
                <b>Badminton courts:</b> BIPARD's indoor badminton court offers
                a professional setting for players of all levels. Impeccably
                maintained flooring and vibrant court markings ensure optimal
                gameplay. Ample seating and state-of-the-art lighting enhance
                the viewing experience. Dedicated equipment zones provide
                everything needed for an immersive and uninterrupted game.
              </li>
              <li>
                <b>Table tennis:</b> BIPARD's indoor table tennis facility
                provides an ideal environment for enthusiasts of the sport. With
                well maintained tables and professional-grade equipment, players
                can enjoy high-quality gameplay. The venue features ample space
                for matches and practice sessions, ensuring a fulfilling
                experience for players of all skill levels. State-of-the-art
                lighting enhances visibility, allowing for precision and
                accuracy in every shot.
              </li>
            </ul>
            <h4>Outdoor Sports Complex:</h4>
            Our expansive outdoor sports complex boasts an array of facilities:
            <ul>
              <li>
                A swimming pool complex for aquatic fitness and relaxation.
              </li>
              <li>A football ground for team sports and matches.</li>
              <li>
                A sprinter track, eco-flex, and joggers track for running
                enthusiasts.
              </li>
              <li>
                Volleyball and basketball courts for group play and tournaments.
              </li>
              <li>Lawn tennis courts for outdoor racquet sports.</li>
              <li>An asphalt cycling track for cycling enthusiasts.</li>
              <li>
                A landscaped park providing serene spaces for outdoor
                recreation.
              </li>
            </ul>
            <h4>Additional Amenities:</h4>
            In addition to the aforementioned facilities, BIPARD offers:
            <ul>
              <li>
                A fully equipped gymnasium for strength training and
                cardiovascular workouts.
              </li>
              <li>
                Various physical activities including Physical Training (PT) and
                Yoga sessions to promote overall well-being.
              </li>
              <li>An open-air theatre for cultural events and performances.</li>
              <li>
                Various lounges in hostels equipped with board games and
                recreational activities for leisure time enjoyment.
              </li>
            </ul>
            At BIPARD, we believe in the importance of staying active and
            healthy. Our comprehensive sports facilities cater to individuals of
            all ages and fitness levels, promoting physical fitness, mental
            well-being, and a sense of community among our members.
          </p>
                </p>
              </div>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <div className="description-card">
                <p>
                  <div className="heading"><h1>About</h1></div>
                  <table class="MsoNormalTable" border="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td>
                          Contemporarily and relevance are the watchwords at the BIPARD and
                          this philosophy is reflected in its well-equipped, modern and
                          computerized library. Since its inception, the library has grown to
                          become a vital part of the academy. It offers a wide range of books
                          and periodicals on different yet related subjects. Trained,
                          professionally qualified and service oriented staff is always eager
                          to help you reach the document you want.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br /><b>Location</b><br />
                          The library is situated in a very strategic place, close to all the
                          class rooms. It is in the main building, surrounded by beautiful
                          lawns and gardens. The indoor plants within the library give you a
                          feeling of closeness to nature, just as the books bring you close to
                          the great minds of all ages.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br /><b>Study Room</b><br />
                          An air-conditioned study room, which can quite easily accommodate about
                          35-40 persons, creates an apt environment for concentrated and
                          in-depth reading and study in the library. Besides this, there is a
                          general reading and study hall where one can comfortably browse
                          through newspapers and magazines. This atrium accommodates more than
                          30 persons at a time, while the large windows bathe the hall in
                          natural light.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br /><b>Collection</b><br />
                          <p>The
                            library possesses volumes on different subjects like Public
                            Administration, Rural Development, Management, Gender Issues,
                            Computers, Public Finance, Urban Studies, Disaster Management,
                            Environment, Social Studies, Education, and Agriculture. A wide
                            range of reference books on the various facets of art, culture and
                            traditions of Bihar, education and other developmental issues enable
                            the library staff to provide an exhaustive reference service to our
                            users.</p>
                          <p>In order to keep the user abreast of emerging areas of knowledge, the
                            BIPARD Library subscribes to many journals on topics of immediate
                            interest to its readers. These include some foreign journals of
                            varied interests.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br /><b>Non Print Media</b><br />
                          Ever since the dawn of the information age, most documentation and reference
                          material has become available on electronic media like Video
                          Cassettes, CDs etc. </td>
                      </tr>
                      <tr>
                        <td>
                          <br /><b>Repository Library</b><br />
                          Government of Bihar has designated BIPARD library as Repository
                          Library for their publications. The work for collection of documents
                          is in progress.</td>
                      </tr>
                    </tbody>
                  </table>
                </p>
              </div>
            </TabPanel>
          </div>
        </div >
      </div >
    </>
  );
};

export default Facilities;
