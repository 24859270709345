import React, { useState } from "react";
import Header from "../../Componets/Header/Header";
import StartupIndiaFooter from "../../Componets/startupIndiaFooter";
import './styles.css'
import img4 from './img4.png'
import smic_role from './smic_role.png'
const SMIC = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "rgba(255,255,255,0.7)", width: "100%", color: "black" }}>
        <Header />
        <div className="container p-5 page-content">
          <h3 className="text-start fw-bold">Start-up Monitoring and Implementation Committee (SMIC)</h3>
          <h5 className="text-start">SMIC shall be constituted under the Chairmanship of Principal Secretary Department of Industries, Government of Bihar.</h5>
          <div className="row aligned-center">
            <div className="col-sm-6">
              <img src={img4} className="w-100 mt-5" />
            </div>
            <div className="col-sm-6">
              <img src={smic_role} className="w-100" />
            </div>
          </div>
        </div>
        <StartupIndiaFooter />
      </div>
    </>
  );
};

export default SMIC;
