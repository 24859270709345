import React, { useState, useEffect } from "react";
import Header from "../Componets/Header/Header";
import StartupIndiaFooter from "../../src/Componets/startupIndiaFooter";
import '../pages/StartupIndia/style.css'
import { certificateFilter, getCertificate, getTraining, trainingFilter } from "../Componets/basicAction";
import CountUp from 'react-countup';
import { dateFormat } from "../shared/dateFunction";
import moment from 'moment';
const AtAGlance = (props) => {
  const [trainingData, setTrainingData] = useState([]);
  const [filteredTrainingData, setFilteredTrainingData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [filterCertificateData, setFilterCertificateData] = useState([]);
  const [centerArray, setCenterArray] = useState(['Gaya', 'Patna']);

  const onGetTraining = async (filterData) => {
    let data = await getTraining(filterData);
    if (data.status) {
      setTrainingData(data.data.data)
      setFilteredTrainingData(data.data.data);
    }
  };
  const onGetCertificate = async (filterData) => {
    let data = await getCertificate(filterData);
    if (data.status) {
      setCertificateData(data.data.data);
    }
  };
  useEffect(() => {
    onGetTraining(trainingFilter);
    onGetCertificate(certificateFilter);
  }, []);

  console.log(certificateData);

  const [typeState, setTypeState] = useState({
    type: "period",
    fromYearMonth: "",
    toYearMonth: "",
    certNo: "",
    filteredToDateOptions: [],
    center: "",
    endDate:"",
    startDate:"",
  });

  const uniqueDatesSet = new Set();

  trainingData.forEach((data) => {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    if (!isNaN(startDate) && !isNaN(endDate)) {
      const startYearMonth = `${startDate.getFullYear()},${startDate.toLocaleString('default', { month: 'long' })}`;
      const endYearMonth = `${endDate.getFullYear()},${endDate.toLocaleString('default', { month: 'long' })}`;

      uniqueDatesSet.add(startYearMonth);
      uniqueDatesSet.add(endYearMonth);
    }
  });

  const uniqueDatesArray = Array.from(uniqueDatesSet);
  const sortedUniqueDatesArray = uniqueDatesArray.sort((a, b) => {
    const dateA = new Date(a.split(',').join(' '));
    const dateB = new Date(b.split(',').join(' '));
    return dateB - dateA;
  });

  const [filteredByDateRange, setFilteredByDateRange] = useState([]);

  const handleChange = (name, value) => {
    console.log(name,value);
    
    let _typeState = { ...typeState, [name]: value };
    setTypeState(_typeState);
  
    let _filteredTrainingData = trainingData;
  
    if (name === 'center') {
      _filteredTrainingData = _filteredTrainingData.filter((data) => data.center === value);
    }
  
    if (name === 'startDate') {
      _filteredTrainingData = _filteredTrainingData.filter((data) => data.startDate === value);
    }

    if (name === 'endDate') {
      const endDate = value;
    
      if (_typeState.startDate) {
        const startDate = _typeState.startDate;
        _filteredTrainingData = trainingData.filter((data) => {
          return data.startDate <= startDate && data.startDate >= endDate;
        });
      } else {
        _filteredTrainingData = _filteredTrainingData.filter((data) => data.endDate === value);
      }
    }
    
    if (name === 'fromYearMonth') {
      const fromIndex = uniqueDatesArray.findIndex((date) => date === value);
      const filteredToDateOptions = uniqueDatesArray.slice(0, fromIndex);
  
      setTypeState((prevState) => ({
        ...prevState,
        toYearMonth: '',
        filteredToDateOptions,
      }));
  
      _filteredTrainingData = _filteredTrainingData.filter((data) => {
        const startDate = new Date(data.startDate);
        const year = startDate.getFullYear();
        const month = startDate.toLocaleString('default', { month: 'long' });
        const yearMonth = `${year},${month}`;
  
        return yearMonth === value;
      });
    }
  
    // Filter by Year and Month Range (toYearMonth)
    if (name === 'toYearMonth') {
      const fromDate = new Date(_typeState.fromYearMonth.split(',').join(' '));
      const toDate = new Date(value.split(',').join(' '));
      toDate.setMonth(toDate.getMonth() + 1, 0); // Adjust to the last day of the selected month
  
      _filteredTrainingData = _filteredTrainingData.filter((data) => {
        const startDate = new Date(data.startDate);
        return startDate >= fromDate && startDate <= toDate;
      });
    }
  
    // Filter by Certificate Number
    if (name === 'certNo') {
      const filteredCertificates = certificateData.filter((certificate) =>
        certificate.certificateNo.toLowerCase().includes(value.toLowerCase())
      );
      setFilterCertificateData(filteredCertificates);
      return; // Exit early if filtering certificates
    }
  
    // Update the filtered training data state
    setFilteredTrainingData(_filteredTrainingData);
  };
  

  console.log(filteredTrainingData);
  const handleCertificateDetails = (id) => {
    // alert(id)
    let _allFilter = { certificateFilter }
    _allFilter.filters = [
      ["trainingId", "=", id]
    ]
    setTypeState((prevState) => ({
      ...prevState,
      type: "certificate"
    }));
    onGetCertificate(_allFilter)
  };

  const renderTableRows = (data) => {
    let filteredData = data;
    // if (typeState.fromYearMonth !== "" && typeState.center !== "") {
    //   filteredData = data.filter((item) => {
    //     return (
    //       item.center === typeState.center &&
    //       dateFormat(item.startDate) === typeState.fromYearMonth
    //     );
    //   });
    // } else if (typeState.fromYearMonth === "" && typeState.center !== "") {
    //   filteredData = data.filter((item) => item.center === typeState.center);
    // }
    // Render table rows based on the filtered data
    if (filteredData && filteredData.length > 0) {
      return filteredData.map((c, i) => (
        <tr key={i}>
          <td>{c.name}</td>
          <td>{c.center}</td>
          <td>{c.nature}</td>
          {/* <td>{c.venue}</td> */}
          {/* <td>{c.noOfDays}</td> */}
          <td
            style={{ cursor: "pointer", color: "royalblue" }}
            onClick={() => handleCertificateDetails(c.trainingId)}
          >
            {c.noOfTrainees}
          </td>
          <td>{c.department}</td>
          {/* <td>{c.coordinator}</td> */}
          <td>{dateFormat(c.startDate)}</td>
          <td>{dateFormat(c.endDate)}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="9" style={{ textAlign: "center" }}>
            No data available
          </td>
        </tr>
      );
    }
  };

  const renderCertificateTableRows = (data) => {
    if (data && data.length > 0) {
      return data.map((c, i) => (
        <tr key={i}>
          <td>{c.certificateNo}</td>
          <td>{c.designation}</td>
          <td>{c.name}</td>
          <td>{c.trainingName}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="4" style={{ textAlign: 'center' }}>No data available</td>
        </tr>
      );
    }
  };

  const getTotalTrainees = (data) => {
    let totalTrainees = 0;
    data.forEach((item) => {
      const traineeCount = parseInt(item.noOfTrainees);
      totalTrainees += isNaN(traineeCount) ? 0 : traineeCount;
    });
    return totalTrainees;
  };
  return (
    <div className="container p-5 page-content">
      <h3 className="text-start fw-bold">At A Glance</h3>
      <div className="row justify-content-center">
        <div className="col-sm-3">
          <div className="number-card">
            <h2 style={{ fontWeight: "bold" }}><CountUp end={filteredTrainingData.length} /></h2>
            <h6>Total Training</h6>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="number-card">
            <h2 style={{ fontWeight: "bold" }}><CountUp end={getTotalTrainees(filteredTrainingData)} /></h2>
            <h6>Total Candidate Trained</h6>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-5 filterDataContainer" id="filterData">
        <div className="form-group col-sm-3">
          <h6>Report Type</h6>
          <select className="form-select" name="type" value={typeState.type} onChange={(e) => handleChange("type", e.target.value)}>
            <option value="">Select...</option>
            <option value="period">Period</option>
            <option value="certificate">Certificate</option>
          </select>
        </div>
        {
          typeState.type === "period" ? (
            <>
              <div className="form-group col-sm-3">
                <h6>Training Center</h6>
                <select
                  className="form-select"
                  name="center"
                  value={typeState.center}
                  onChange={(e) => handleChange("center", e.target.value)}
                >
                  <option value="">Select...</option>
                  {centerArray?.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-sm-3">
                <h6>Start Date</h6>
                <input
                type="date"
                className="form-control"
                name="startDate"
                value={typeState.startDate}
                onChange={(e) => handleChange("startDate", e.target.value)}
              />
                {/* <select
                  className="form-select"
                  name="fromYearMonth"
                  value={typeState.fromYearMonth}
                  onChange={(e) => handleChange("fromYearMonth", e.target.value)}
                >
                  <option value="">Select...</option>
                  {sortedUniqueDatesArray?.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))}
                </select> */}
              </div>
              <div className="form-group col-sm-3">
                <h6>End Date</h6>
                <input
                type="date"
                className="form-control"
                name="endDate"
                value={typeState.endDate}
                onChange={(e) => handleChange("endDate", e.target.value)}
              />
                {/* <select
                  className="form-select"
                  name="toYearMonth"
                  value={typeState.toYearMonth}
                  onChange={(e) => handleChange("toYearMonth", e.target.value)}
                >
                  <option value="">Select...</option>
                  {typeState.filteredToDateOptions?.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))}
                </select> */}
              </div>
            </>
          ) : (
            <div className="form-group col-sm-3">
              <h6>Certificate No</h6>
              <input
                type="text"
                className="form-control"
                name="certNo"
                placeholder="Enter certificate number to search"
                value={typeState.certNo}
                onChange={(e) => handleChange("certNo", e.target.value)}
              />
            </div>
          )
        }
      </div>
      <div className="row justify-content-center mt-2">
        <div className="col-sm-12" style={{ maxWidth: "100%", overflowX: "auto" }}>
          <div style={{ maxHeight: '400px', minWidth: "1200px" }}>
            {
              typeState.type == "period" ?

                <table className="bg-white shadow table table-stripped table-hover rounded mt-3" style={{ border: '1px solid black', textAlign: 'center', color: '#000869', fontSize: "14px" }}>
                  <tr style={{ backgroundColor: '#000869', color: 'white' }}>
                    <th style={{ width: "100px" }}>Name</th>
                    <th>Center</th>
                    <th>Nature</th>
                    {/* <th>Venue</th> */}
                    {/* <th>No of Days</th> */}
                    <th>No of Trainees</th>
                    <th>Department</th>
                    {/* <th>Coordinator</th> */}
                    <th style={{ width: "200px" }}>Start Date</th>
                    <th style={{ width: "200px" }}>End Date</th>
                  </tr>
                  <tbody>
                    {renderTableRows(filteredTrainingData)}
                  </tbody>

                </table> : <table className="bg-white shadow table table-stripped table-hover rounded-3 mt-3" style={{ border: '1px solid black', textAlign: 'center', color: '#000869' }}>
                  <tr style={{ backgroundColor: '#000869', color: 'white' }}>
                    <th>Certificate No</th>
                    <th>Designation</th>
                    <th>Name</th>
                    <th>Training Name</th>
                  </tr>
                  <tbody>
                    {typeState.certNo == "" ? renderCertificateTableRows(certificateData) : renderCertificateTableRows(filterCertificateData)}
                  </tbody>
                </table>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtAGlance;
