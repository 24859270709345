import React, { useState } from "react";
import './styles.css'
import organisation from '../../assets/img/organisation.jpeg'
const Organisation = (props) => {
  return (
    <>
      <div className="container p-5 page-content">
        <div className="row aligned-center">
          <div className="col-sm-12">
            <div>
              <p>
                <h2 className="text-start fw-bold">Organisation Structure</h2>
                <img src={organisation} style={{width:"100%",borderRadius:"10px"}}/>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organisation;
