import React from "react";

import "bootstrap/dist/css/bootstrap.css";
import './Header.css';
import bipard from '../../assets/img/BIPARD-logo.png';
import logo from './udyami-logo.png';
import { Link } from "react-router-dom";
import instagram from "../../assets/img/instagram.png";
import facebook from "../../assets/img/facebook.png";
import twitter from "../../assets/img/twitter.png";
import '../../assets/css/hover.css'
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useState } from "react";

const Header = () => {


    const dispatch = useDispatch();
    let checkPath = window.location.pathname.split('/');
    console.log(checkPath)
    // const googleTranslateElementInit = () => {
    //     new window.google.translate.TranslateElement(
    //         {
    //             // pageLanguage: 'en',
    //             includedLanguages: 'en,hi', // include this for selected languages
    //             // layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
    //         },
    //         'google_translate_element'
    //     );
    // };

    useEffect(() => {
        // let script = document.createElement('script');
        // script = document.createElement('script');
        // script.setAttribute(
        //     'src',
        //     '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        // );
        // document.body.appendChild(script);
        // window.googleTranslateElementInit = googleTranslateElementInit;
        // console.log('i fire once');
    }, []);


    return (
        <>


            <div style={{ zIndex: "9999", position: "fixed", left: "0px", top: "40%", height: "140px", width: "60px" }}>
                <div className="p-2 side-social-media">
                    <a className="hvr-grow" href="https://www.facebook.com/bipard/" target="_blank"><img src={facebook} /></a><br />
                    <a className="hvr-grow" href="https://twitter.com/BipardGaya" target="_blank"><img src={twitter} /></a><br />
                    <a className="hvr-grow" href="https://www.instagram.com/bipard_gaya/" target="_blank"><img src={instagram} /></a>
                </div>
            </div>

            <div className="header-start-home">

                <div className="container-fluid top-header text-center" style={{ backgroundColor: "#000869" }}>
                    <div className="row">
                        <div className="col-sm-9" >
                            Helpline: <span className="text-white font-weight-bold"> +91 612 245 2585</span> <span className="ms-2 me-2">|</span> Email : <span className="text-white">bipard.gaya-bih@bihar.gov.in, bipardgaya@yahoo.com, bipardpatna@yahoo.in</span>
                        </div>
                        {/* 
                        <div className="col-sm-4" style={{ marginTop: "-10px" }}>

                            <span id="google_translate_element" ></span>

                        </div> */}
                    </div>

                </div>



                <div className="row w-100 justify-content-center p-0 m-0">
                    <div className="col-sm-12 p-0 m-0">
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                <Link className="navbar-brand" to="/"> <table><tr><td><img src={bipard} alt="No Img" style={{ height: "77px", borderRadius: "5px", marginBottom: '8px', width: 'auto' }} /></td><td>Bihar Institute of Public Administration<br /> & Rural Development<br />बिहार लोक प्रशासन एवं ग्रामीण विकास संस्थान</td></tr></table></Link>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon">=</span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNavDropdown" style={{ backgroundColor: "#af2910", borderRadius: "10px", height: "55px" }}>

                                    <ul className="navbar-nav ms-auto startupindia-home">
                                        <li className={checkPath[1] == "" ? "nav-item active" : "nav-item"}>
                                            <a className="nav-link" aria-current="page" href="/">Home</a>
                                        </li>
                                        <li className={checkPath[1] == "about"? "nav-item dropdown active" : "nav-item dropdown"}>
                                            <a className="nav-link dropdown-toggle" href="/about" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                About BIPARD
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="/about#0">About</a></li>
                                                <li><a className="dropdown-item" href="/about#1">Vision</a></li>
                                                <li><a className="dropdown-item" href="/about#2">Mission</a></li>
                                                <li><a className="dropdown-item" href="/about#3">Objective</a></li>
                                                <li><a className="dropdown-item" href="/about#4">General Council</a></li>
                                                <li><a className="dropdown-item" href="/about#5">MoA</a></li>
                                            </ul>
                                        </li>
                                        <li className={checkPath[1] == "director-message" || checkPath[1] == "organization"|| checkPath[1] == "magazine"|| checkPath[1] == "dg-review" ? "nav-item dropdown active" : "nav-item dropdown"}>
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                The Academy
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="/director-message">DG Message</a></li>
                                                <li><a className="dropdown-item" href="/organization">Organization</a></li>
                                                <li><a className="dropdown-item" href="/magazine">Magazine</a></li>
                                                <li><a className="dropdown-item" href="/dg-review">DG Review</a></li>
                                                {/* <li>
                                                    <a className="dropdown-item" href="#">
                                                        Bihar Startup Policy 2022 &raquo;
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li>
                                                            <a className="dropdown-item" download>Hindi</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" download>English</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">Submenu item 3 &raquo; </a>
                                                            <ul className="dropdown-menu dropdown-submenu">
                                                                <li>
                                                                    <a className="dropdown-item" href="#">Multi level 1</a>
                                                                </li>
                                                                <li>
                                                                    <a className="dropdown-item" href="#">Multi level 2</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">Submenu item 4</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">Submenu item 5</a>
                                                        </li>
                                                    </ul>
                                                </li> */}
                                            </ul>
                                        </li>
                                        <li className={checkPath[1] == "training-rules" || checkPath[1] == "training-calendar" || checkPath[1] == "ataglance" ? "nav-item dropdown active" : "nav-item dropdown"}>
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Training
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                {/* <li><a className="dropdown-item" href="#">About Training</a></li> */}
                                                <li><a className="dropdown-item" href="/training-rules">Training Rule</a></li>
                                                <li><a className="dropdown-item" href="/training-calendar">Training Calendar</a></li>
                                                <li><a className="dropdown-item" href="/ataglance">At a Glance</a></li>
                                            </ul>
                                        </li>
                                        <li className={checkPath[1] == "facilities" ? "nav-item dropdown active" : "nav-item dropdown"}>
                                            <a className="nav-link dropdown-toggle" href="/facilities" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Facilities
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="/facilities#0">Computer Lab</a></li>
                                                <li><a className="dropdown-item" href="/facilities#1">Dining Hall</a></li>
                                                <li><a className="dropdown-item" href="/facilities#2">Hostel</a></li>
                                                <li><a className="dropdown-item" href="/facilities#3">Smart Classroom</a></li>
                                                <li><a className="dropdown-item" href="/facilities#4">Health Center</a></li>
                                                <li><a className="dropdown-item" href="/facilities#5">Sports / Gym</a></li>
                                                <li><a className="dropdown-item" href="/facilities#6">Yoga / PT</a></li>
                                                <li><a className="dropdown-item" href="/facilities#7">Library</a></li>
                                                {/* <li>
                                                    <a className="dropdown-item" href="#">
                                                        Market Access &raquo;
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li>
                                                            <a className="dropdown-item" href="/startup-programs-challenges">Startup Programs and<br /> Challenges</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="/trade-fair-expos">Trade Fair and Expos</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">Procurement Policies<br /> by Government &raquo; </a>
                                                            <ul className="dropdown-menu dropdown-submenu">
                                                                <li>
                                                                    <a className="dropdown-item" download>Bihar Purchase Rule </a>
                                                                </li>
                                                                <li>
                                                                    <a className="dropdown-item" download>Bihar Startup Policy - 2022</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item" href="#">Important Links for<br /> Marketing &raquo; </a>
                                                            <ul className="dropdown-menu dropdown-submenu">
                                                                <li>
                                                                    <a className="dropdown-item" href="http://kvibbihar.com/VendorRegistration.aspx#contentstart">Vendor registration<br /> at Khadi Mall </a>
                                                                </li>
                                                                <li>
                                                                    <a className="dropdown-item" href="https://mkp.gem.gov.in/registration/signup#!/seller">GeM registration </a>
                                                                </li>
                                                            </ul>
                                                        </li>

                                                    </ul>
                                                </li> */}
                                            </ul>
                                        </li>
                                        <li className={checkPath[1] == "knowledge-partner" ? "nav-item active" : "nav-item"}>
                                            <a className="nav-link" href="/knowledge-partner">Knowledge Partner</a>
                                        </li>
                                        <li  className={checkPath[1] == "rti" ? "nav-item active" : "nav-item"}>
                                            <a className="nav-link" href="/rti">RTI</a>
                                        </li>
                                        <li  className={checkPath[1] == "contacts" ? "nav-item active" : "nav-item"}>
                                            <a className="nav-link" href="/contacts">Visit Us</a>
                                        </li>

                                        {/* <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Bihar Startup Policy 2022
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" download href={policy2}>Hindi</a></li>
                                                <li><a className="dropdown-item" download href={policy}>English</a></li>

                                            </ul>
                                        </li> */}

                                        {/* <div className="bg-light rounded-3 ms-5 login-dropdown">
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle login_signup" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Login
                                                </a>

                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item text-dark" href="/login">Login</a></li>
                                                    <li><a className="dropdown-item text-dark" href="/apply-new">Register</a></li>                                                    
                                                    <li><a className="dropdown-item text-dark" href="/login">Sign In with Startup India</a></li>
                                                </ul>
                                            </li>
                                        </div> */}


                                    </ul>

                                </div>
                            </div>
                        </nav>
                    </div>
                </div>


            </div >
        </>
    )
}

export default Header;